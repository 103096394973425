import React from "react"
import Nav from "../shared/Nav";
import ShowHeader from "../shared/headers/ShowHeader";
import AdminHeader from "../shared/headers/AdminHeader";

export default class Show extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: "",
      onDesktop: true,
    }
  }

  componentDidMount() {
    let onDesktop = window && window.innerWidth >= 1000;

    this.setState({
      onDesktop: onDesktop,
    })
  }

  render () {
    let { product, showdrop_logo } = this.props;
    let { onDesktop } = this.state;
    let pageName = 'brands';

    return (
      <div className="container-fluid bg-extra-gray fs-14">
        <AdminHeader
          logo={showdrop_logo}
          onDesktop={onDesktop}
          pageName={pageName}
          counts={this.props.counts}
        />

        <div className="row full-height">
          <div className={`col col-lg-2 nav-restrict-size ${onDesktop ? '' : 'hide'}`}>
            <Nav
              active={pageName}
              counts={this.props.counts}
            />
          </div>

          <div className="col bg-white">
            <ShowHeader
              name={product.line_1}
              subtext={null}
              url_edit={product.url_edit}
              url_new={null}
            />

            <dl className="row">
              <dt className="col-sm-3 text-right">ID</dt>
              <dd className="col-sm-9">{product.id}</dd>

              <dt className="col-sm-3 text-right">Name</dt>
              <dd className="col-sm-9">{product.name}</dd>

              <dt className="col-sm-3 text-right">Description</dt>
              <dd className="col-sm-9">{product.description}</dd>

              <dt className="col-sm-3 text-right">Categories</dt>
              <dd className="col-sm-9">
                <ul style={{ padding:"0",margin:"0" }}>
                  {product.categories.split(',').map(str => {
                    return (
                      <li>{str}</li>
                    )
                  })}
                </ul>
              </dd>

              <dt className="col-sm-3 text-right">Tags</dt>
              <dd className="col-sm-9">
                <ul style={{ padding:"0",margin:"0" }}>
                  {product.tags.split(',').map(str => {
                    return (
                      <li>{str}</li>
                    )
                  })}
                </ul>
              </dd>

              <dt className="col-sm-3 text-right">Image</dt>
              <dd className="col-sm-9">
                {product.image ? (
                  <img className="w300 h-auto" src={product.image_url} />
                ) : (
                  null
                )}

                <div>{product.image_name}</div>
              </dd>

              <dt className="col-sm-3 text-right">Brand</dt>
              <dd className="col-sm-9">
                <a href={product.brand.url}>{product.brand.name}</a>
              </dd>
            </dl>
          </div>
        </div>
      </div>
    );
  }
}
