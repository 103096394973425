import React from "react"
import Nav from "../shared/Nav";
import IndexHeader from "../shared/headers/IndexHeader";
import AdminHeader from "../shared/headers/AdminHeader";
import Row from "./Row";

export default class Index extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      onDesktop: true,
    };
  }

  componentDidMount() {
    let onDesktop = window && window.innerWidth >= 1000;

    this.setState({
      onDesktop: onDesktop
    })
  }

  render () {
    let { api_requests, showdrop_logo } = this.props;
    let { onDesktop } = this.state;
    let pageName = 'api_requests';

    return (
      <div className="container-fluid bg-extra-gray fs-14 admin">
        <AdminHeader
          logo={showdrop_logo}
          counts={this.props.counts}
          onDesktop={onDesktop}
          pageName={pageName}
        />

        <div className="row full-height">
          <div className={`col col-lg-2 nav-restrict-size ${onDesktop ? '' : 'hide'}`}>
            <Nav
              active={pageName}
              counts={this.props.counts}
            />
          </div>

          <div className="col col-lg-10 bg-white h1000 o-auto mobile-block">
            <IndexHeader
              name={'API Requests'}
              url={null}
            />

            <table className="table table-hover" style={{ tableLayout:"fixed",width:"100%" }}>
              <thead>
                <tr>
                  <th scope="col" style={{ width:"50px" }}></th>
                  <th scope="col" style={{ width:"50px" }}>ID</th>
                  <th scope="col">Created At</th>
                  <th scope="col">Endpoint</th>
                  <th scope="col">Params</th>
                  <th scope="col" style={{ maxWidth:"100px" }}>Body</th>
                  <th scope="col" className="w200"></th>
                </tr>
              </thead>
              <tbody>
                {api_requests.map(request => {
                  return (
                    <Row request={request} />
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}
