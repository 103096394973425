import React from "react";
import ahoy from "ahoy.js";

export default class Start extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      displayEmailForm: false
    };

    this.clickFacebook = this.clickFacebook.bind(this);
    this.clickGoogle = this.clickGoogle.bind(this);
    this.clickLinkedin = this.clickLinkedin.bind(this);
    this.handleDisplayEmailForm = this.handleDisplayEmailForm.bind(this);
  }

  clickFacebook(event) {
    ahoy.track("LOGIN CLICK (FACEBOOK)", { machine_uuid: this.props.machine.uuid, campaign_ids: this.props.campaign_ids });
  }

  clickGoogle(event) {
    ahoy.track("LOGIN CLICK (GOOGLE)", { machine_uuid: this.props.machine.uuid, campaign_ids: this.props.campaign_ids });
  }

  clickLinkedin(event) {
    ahoy.track("LOGIN CLICK (LINKEDIN)", { machine_uuid: this.props.machine.uuid, campaign_ids: this.props.campaign_ids });
  }

  handleDisplayEmailForm(event) {
    ahoy.track("LOGIN CLICK (EMAIL)", { machine_uuid: this.props.machine.uuid, campaign_ids: this.props.campaign_ids });

    this.setState({
      displayEmailForm: true
    });
  }

  render() {
    let { machine, url_privacy, url_terms, showdrop_logo } = this.props;

    return (
      <div className="h800">
        <div className="container pb-40 pt-100">
          <div className="row align-items-center justify-content-center">
            <div className="col-10 col-md-6 col-lg-4 pt-30">
              <h2 className="color-white center-text fs-15rem bold">
                Continue below for PIN to unlock your free sample & an exclusive discount code!
              </h2>
            </div>
          </div>

          <div className="row align-items-center justify-content-center">
            <div className="col-10 col-md-6 col-lg-4 pt-30">
              <h3 className="color-primary fs-16 pt-10 bold pdb-5">Get PIN now</h3>
            </div>
          </div>

          <div className="row align-items-center justify-content-center">
            <div className="col-10 col-md-6 col-lg-4">
              <div onClick={this.clickGoogle}>
                <a rel="nofollow" data-method="post" href={machine.google_link}>
                  <div className="oauth-btn bg-black border-tertiary-2 br-4">
                    <div className="oauth-icon-wrapper bg-black mt-0">
                      <img className="oauth-icon-svg" src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg" />
                    </div>

                    <p className="btn-text color-tertiary thin">
                      <b>Continue with Google</b>
                    </p>
                  </div>
                </a>
              </div>
            </div>
          </div>

          <div className="row align-items-center justify-content-center pt-10">
            <div className="col-10 col-md-6 col-lg-4">
              <div onClick={this.clickFacebook}>
                <a rel="nofollow" data-method="post" href={machine.facebook_link}>
                  <div className="oauth-btn bg-black border-tertiary-2 br-4">
                    <div className="oauth-icon-wrapper bg-black mt-0">
                      <img className="oauth-icon-svg" src="https://upload.wikimedia.org/wikipedia/en/0/04/Facebook_f_logo_%282021%29.svg" />
                    </div>

                    <p className="btn-text color-tertiary thin">
                      <b>Continue with Facebook</b>
                    </p>
                  </div>
                </a>
              </div>
            </div>
          </div>

          <div className="row align-items-center justify-content-center pt-10">
            <div className="col-10 col-md-6 col-lg-4">
              <div onClick={this.clickLinkedin}>
                <a rel="nofollow" data-method="post" href={machine.linkedin_link}>
                  <div className="oauth-btn bg-black border-tertiary-2 br-4">
                    <div className="oauth-icon-wrapper bg-black mt-0">
                      <img className="oauth-icon-svg" src="https://upload.wikimedia.org/wikipedia/commons/c/ca/LinkedIn_logo_initials.png" />
                    </div>

                    <p className="btn-text color-tertiary thin">
                      <b>Continue with LinkedIn</b>
                    </p>
                  </div>
                </a>
              </div>
            </div>
          </div>

          {
            machine.id == 1 || !machine.automatic
              ? null
              : <DisplayEmailForm
                  displayEmailForm={this.state.displayEmailForm}
                  handleDisplayEmailForm={this.handleDisplayEmailForm}
                  newCustomersUrl={machine.new_customers_url}
                />
          }

          <div className="row align-items-center justify-content-center pt-10">
            <div className="col-10 col-md-6 col-lg-4">
              <p className="center-text color-light-gray fs-10 pt-10 italic">
                <span className="pdr-2">By using Showdrop, you agree to our</span>
                <a className="color-primary" href={url_terms} target="_blank">Terms of Service</a>
                <span className="pdlr-2">and</span>
                <a className="color-primary" href={url_privacy} target="_blank">Privacy Policy</a>.
              </p>
            </div>
          </div>
        </div>

        <div className="fixed-bottom bg-black pb-10">
          <div className="bb-primary bt-primary h10"></div>
          <img className="w120 floatr" src={showdrop_logo} />
        </div>
      </div>
    );
  }
}

function DisplayEmailForm(props) {
  let { displayEmailForm, handleDisplayEmailForm, newCustomersUrl } = props;

  if (displayEmailForm) {
    return (
      <>
        <div className="row align-items-center justify-content-center">
          <p className="pt-16 italic color-light-gray">- OR -</p>
        </div>

        <div className="row align-items-center justify-content-center">
          <div className="col-10 col-md-6 col-lg-4">
            <form className="form-landing" action={newCustomersUrl} accept-charset="UTF-8" method="post">
              <label className="sr-only" for="customer_email">Email Address</label>

              <div className="d-flex">
                <input autofocus="autofocus" autocomplete="email" className="form-control bg-dark-gray border-dark-gray color-white br-4" placeholder="Email Address" required="required" type="email" name="customer[email]" id="customer_email"></input>

                <button className="btn btn-sm btn-block color-white bold w50" type="submit" ref={(node) => {
                  if (node) {
                    node.style.setProperty("background-color", "#20cb9a", "important")
                  }
                }}>
                  <i className="fas fa-angle-double-right fs-20"></i>
                </button>
              </div>
            </form>
          </div>
        </div>
      </>
    )
  } else {
    return (
      <div className="row align-items-center justify-content-center">
        <div className="col-10 col-md-6 col-lg-4 pt-30">
          <h3 className="color-light-gray fs-16 pdb-5 center-text" onClick={handleDisplayEmailForm}>
            <i className="fas fa-chevron-right pdr-5 fs-12"></i>
            <span className="underline">or get PIN via email</span>
          </h3>
        </div>
      </div>
    )
  }
}
