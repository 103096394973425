import React from "react"
import Nav from "../shared/Nav";
import ShowHeader from "../shared/headers/ShowHeader";
import AdminHeader from "../shared/headers/AdminHeader";

export default class Show extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      onDesktop: true
    }
  }

  componentDidMount() {
    let onDesktop = window && window.innerWidth >= 1000;

    this.setState({
      onDesktop: onDesktop
    })
  }

  render () {
    let { campaign_package, showdrop_logo, url_new } = this.props;
    let { onDesktop } = this.state;
    let pageName = 'brands';

    let addLineBreak = (str) => {
      if (str) {
        return (
          str.split('\n').map((subStr) => {
            return (
              <>
                {subStr}
                <br />
              </>
            );
          })
        )
      } else {
        return "-"
      }
    }

    return (
      <div className="container-fluid bg-extra-gray fs-14">
        <AdminHeader
          logo={showdrop_logo}
          onDesktop={onDesktop}
          pageName={pageName}
          counts={this.props.counts}
        />

        <div className="row full-height">
          <div className={`col col-lg-2 nav-restrict-size ${onDesktop ? '' : 'hide'}`}>
            <Nav
              active={pageName}
              counts={this.props.counts}
            />
          </div>

          <div className="col bg-white">
            <ShowHeader
              name={`${campaign_package.name}`}
              subtext={null}
              url_edit={campaign_package.url_edit}
              url_new={url_new}
            />

            <dl className="row">
              <dt className="col-sm-3 text-right">ID</dt>
              <dd className="col-sm-9">{campaign_package.id}</dd>

              <dt className="col-sm-3 text-right">Name</dt>
              <dd className="col-sm-9">{campaign_package.name}</dd>

              <dt className="col-sm-3 text-right">Static Discount Name</dt>
              <dd className="col-sm-9">{campaign_package.static_code || '-'}</dd>

              <dt className="col-sm-3 text-right">Discount Amount (Shopify)</dt>
              <dd className="col-sm-9">{campaign_package.discount_amount || '-'}</dd>

              <dt className="col-sm-3 text-right">Discount Type (Shopify)</dt>
              <dd className="col-sm-9">{campaign_package.discount_type || '-'}</dd>

              <dt className="col-sm-3 text-right">Discount Target (Shopify)</dt>
              <dd className="col-sm-9">{campaign_package.discount_target || '-'}</dd>

              <dt className="col-sm-3 text-right">Discount Disclaimer</dt>
              <dd className="col-sm-9">{campaign_package.discount_disclaimer || '-'}</dd>

              <dt className="col-sm-3 text-right">Price Rule ID (Shopify)</dt>
              <dd className="col-sm-9">{campaign_package.price_rule || '-'}</dd>

              <dt className="col-sm-3 text-right">Transaction Text Append</dt>
              <dd className="col-sm-9">
                {addLineBreak(campaign_package.transaction_text_append)}
              </dd>

              <dt className="col-sm-3 text-right">Instruction Text Append</dt>
              <dd className="col-sm-9">
                {addLineBreak(campaign_package.instruction_text_append)}
              </dd>

              <dt className="col-sm-3 text-right">Marketing Text Append</dt>
              <dd className="col-sm-9">
                {addLineBreak(campaign_package.marketing_text_append)}
              </dd>

              <dt className="col-sm-3 text-right">Marketing Notification Append</dt>
              <dd className="col-sm-9">
                {addLineBreak(campaign_package.marketing_notification_append)}
              </dd>

              <dt className="col-sm-3 text-right">Marketing Text Interval</dt>
              <dd className="col-sm-9">{campaign_package.marketing_text_interval || '-'} minutes</dd>

              <dt className="col-sm-3 text-right">Product Review Product</dt>
              <dd className="col-sm-9">{campaign_package.product_review_product.name || '-'}</dd>

              <dt className="col-sm-3 text-right">Product Review Text Append</dt>
              <dd className="col-sm-9">
                {addLineBreak(campaign_package.product_review_text_append)}
              </dd>

              <dt className="col-sm-3 text-right">Product Review Text Interval</dt>
              <dd className="col-sm-9">{campaign_package.product_review_text_interval || '-'} minutes</dd>

              <dt className="col-sm-3 text-right">Engagement Link</dt>
              <dd className="col-sm-9">{campaign_package.engagement_link || '-'}</dd>

              <dt className="col-sm-3 text-right">Enable Rebate?</dt>
              <dd className="col-sm-9">{campaign_package.enable_rebate ? "true" : "false"}</dd>

              <dt className="col-sm-3 text-right">Rebate Discount Amount</dt>
              <dd className="col-sm-9">{campaign_package.rebate_discount_amount || '-'}</dd>

              <dt className="col-sm-3 text-right">Rebate Discount Type</dt>
              <dd className="col-sm-9">{campaign_package.rebate_discount_type || '-'}</dd>

              <dt className="col-sm-3 text-right">Rebate Welcome Text</dt>
              <dd className="col-sm-9">
                {addLineBreak(campaign_package.rebate_response)}
              </dd>

              <dt className="col-sm-3 text-right">Rebate Disclaimer</dt>
              <dd className="col-sm-9">{campaign_package.rebate_disclaimer || '-'}</dd>
            </dl>
          </div>
        </div>
      </div>
    );
  }
}
