import React from "react"

export default class MachineRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loadedMachine: []
    }

    this.fetchMachineData = this.fetchMachineData.bind(this)
  }

  componentDidMount() {
    this.fetchMachineData()
  }

  fetchMachineData() {
    let that = this;
    const formData = new FormData();
    let data = JSON.stringify({
      id: this.props.machine.id,
    })
    formData.append('machine', data)

    fetch(this.props.machine.url_fetch_machine_data, {
      method: 'POST',
      headers: {},
      body: formData
    }).then(response => {
      return response.json();
    }).then((data) => {
      if (data.errors) {
        that.setState({
          errors: data.errors
        })
      } else {
        that.setState({
          loadedMachine: data.machine_data,
          loading: false
        })
      }
    })
  }

  render () {
    let { loadedMachine, loading } = this.state;

    {
      return (
        loadedMachine.length > 0 ? (
          loadedMachine.map(row => {
            let dwells = row ? row.dwells : null
            let dwellAvg = dwells && row ? (row.dwell_seconds / dwells) : null

            return (
              <>
                {
                  loading ? (
                    <tr>
                      <i className="fas fa-circle-notch fa-spin" style={{ fontSize:"50px",padding:"10px",marginTop:"150px" }}></i>
                      <div style={{ paddingLeft:"4px",fontSize:"18px" }}>Loading Engagement...</div>
                    </tr>
                  ) : (
                    <tr key={`machine-${row.machine.id}-engagement-${row.date}`} style={{ fontWeight:`${row.current_week == 1 ? "bold" : "normal"}` }}>
                      <td>{row.machine.name}</td>
                      <td>{row.date}</td>
                      <td className="right-text">{numberWithCommas(row.impressions)}</td>
                      <td className="right-text">{numberWithCommas(dwells)}</td>
                      <td className="right-text">{dwells > 0 ? Math.round(dwellAvg * 100) / 100 : 0}</td>
                      <td className="right-text">{numberWithCommas(row.unique_engagement_count)}</td>
                      <td className="right-text">{numberWithCommas(row.unique_pin_requests)}</td>
                      <td className="right-text">{numberWithCommas(row.pin_releases_showdrop_machine)}</td>
                      <td className="right-text">{numberWithCommas(row.pin_releases_machine)}</td>
                      <td className="right-text">{numberWithCommas(row.pin_releases_returning_to_machine)}</td>
                      <td className="right-text">{numberWithCommas(row.eligible_pin_releases_showdrop_machine)}</td>
                      <td className="right-text">{numberWithCommas(row.eligible_pin_releases_machine)}</td>
                      <td className="right-text">{numberWithCommas(row.eligible_pin_releases_returning_to_machine)}</td>
                      <td className="right-text">{numberWithCommas(row.pin_inputs)}</td>
                      <td className="right-text">{numberWithCommas(row.valid_pin_inputs)}</td>
                      <td className="right-text">{numberWithCommas(row.unique_claimed)}</td>
                      <td className="right-text">{numberWithCommas(row.total_claimed)}</td>
                      <td className="right-text">{row.average_eligible_count > 0 ? Math.round(row.average_eligible_count * 100) / 100 : 0}</td>
                      <td className="right-text">{numberWithCommas(row.dispense_errors)}</td>
                      <td className="right-text">{numberWithCommas(row.invalid_pins)}</td>
                      <td className="right-text">{numberWithCommas(row.already_claimed)}</td>
                      <td className="right-text">{numberWithCommas(row.shop_clicks)}</td>
                      <td className="right-text">{numberWithCommas(row.engagement_clicks)}</td>
                      <td className="right-text">{numberWithCommas(row.rebate_sign_ups)}</td>
                      <td className="right-text">{numberWithCommas(row.orders)}</td>
                    </tr>
                  )
                }
              </>
            )
          })
        ) : (
          null
        )
      )
    }
  }
}

function numberWithCommas(x) {
  if (x == null) {
    return x
  } else {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
}
