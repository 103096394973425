import React from "react"
import Nav from "../../shared/Nav";
import IndexHeader from "../../shared/headers/IndexHeader";
import AdminHeader from "../../shared/headers/AdminHeader";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Column, Table, AutoSizer, defaultTableRowRenderer } from 'react-virtualized';
import Select from "react-select";

export default class Index extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      downloading: false,
      errors: '',
      eventNames: [],
      fullyLoaded: false,
      loadingEvents: false,
      onDesktop: true,
      selectedEventType: '-',
      selectedMachineId: "",
      selectedCustomerId: "all",
      showHidden: true,
      sortedEvents: [],
    }

    this.downloadCsv = this.downloadCsv.bind(this)
    this.downloadMachineLogCsv = this.downloadMachineLogCsv.bind(this)
    this.downloadTransactionDetailsCsv = this.downloadTransactionDetailsCsv.bind(this)
    this.filterEventsByMachine = this.filterEventsByMachine.bind(this)
    this.filterEventsByType = this.filterEventsByType.bind(this)
    this.setSelectedCustomerId = this.setSelectedCustomerId.bind(this)
    this.getAllEvents = this.getAllEvents.bind(this)
    this.getEvents = this.getEvents.bind(this)
    this.toggleHidden = this.toggleHidden.bind(this)
  }

  componentDidMount() {
    let onDesktop = window && window.innerWidth >= 1000;

    this.setState({
      onDesktop: onDesktop,
      loadingEvents: true
    })

    let that = this;

    fetch(this.props.url, {
      method: 'GET',
      headers: {}
    }).then(response => {
      return response.json();
    }).then((data) => {
      if (data.errors) {
        that.setState({
          errors: data.errors
        })
      } else {
        that.setState({
          loadingEvents: false,
          sortedEvents: data.events,
          eventNames: data.event_names,
        })
      }
    })
  }

  downloadCsv() {
    let that = this;
    that.setState({
      downloading: true
    })

    fetch(this.props.url_download_csv, {
      method: 'GET',
      headers: {}
    }).then(response => {
      return response.blob();
    }).then((blob) => {
      that.setState({
        downloading: false
      })
      const url = window.URL.createObjectURL(
        new Blob([blob]),
      );
      const link = document.createElement('a');
      link.href = url;
      var today = new Date(),
      date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

      link.setAttribute(
        'download',
        `events_${date}.csv`,
      );

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    })
  }

  downloadMachineLogCsv() {
    let that = this;
    that.setState({
      downloading: true
    })

    fetch(this.props.url_download_machine_logs_csv, {
      method: 'GET',
      headers: {}
    }).then(response => {
      return response.blob();
    }).then((blob) => {
      that.setState({
        downloading: false
      })
      const url = window.URL.createObjectURL(
        new Blob([blob]),
      );
      const link = document.createElement('a');
      link.href = url;
      var today = new Date(),
      date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

      link.setAttribute(
        'download',
        `machine-logs_${date}.csv`,
      );

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    })
  }

  downloadTransactionDetailsCsv() {
    let that = this;
    that.setState({
      downloading: true
    })

    fetch(this.props.url_download_transaction_details_csv, {
      method: 'GET',
      headers: {}
    }).then(response => {
      return response.blob();
    }).then((blob) => {
      that.setState({
        downloading: false
      })
      const url = window.URL.createObjectURL(
        new Blob([blob]),
      );
      const link = document.createElement('a');
      link.href = url;
      var today = new Date(),
      date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

      link.setAttribute(
        'download',
        `transaction-details_${date}.csv`,
      );

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    })
  }

  filterEventsByMachine(event) {
    let machineId = Number(event.target.value)

    this.setState({
      selectedMachineId: machineId,
    })
  }

  filterEventsByType(event) {
    let eventType = event.target.value;

    this.setState({
      selectedEventType: eventType,
    })
  }

  setSelectedCustomerId(option) {
    this.setState({
      selectedCustomerId: option.value,
    })
  }

  getAllEvents() {
    this.setState({
      loadingEvents: true
    })

    let that = this;

    fetch(this.props.url_all, {
      method: 'GET',
      headers: {}
    }).then(response => {
      return response.json();
    }).then((data) => {
      if (data.errors) {
        that.setState({
          errors: data.errors
        })
      } else {
        that.setState({
          loadingEvents: false,
          fullyLoaded: true,
          sortedEvents: data.events,
          eventNames: data.event_names,
        })
      }
    })
  }

  getEvents() {
    this.setState({
      loadingEvents: true
    })

    let that = this;

    fetch(this.props.url, {
      method: 'GET',
      headers: {}
    }).then(response => {
      return response.json();
    }).then((data) => {
      if (data.errors) {
        that.setState({
          errors: data.errors
        })
      } else {
        that.setState({
          loadingEvents: false,
          sortedEvents: data.events,
          eventNames: data.event_names,
        })
      }
    })
  }

  toggleHidden() {
    let hidden = this.state.showHidden;

    this.setState({
      showHidden: !hidden,
    })
  }

  render () {
    let { machines, showdrop_logo } = this.props;
    let {
      downloading,
      eventNames,
      fullyLoaded,
      loadingEvents,
      onDesktop,
      selectedEventType,
      selectedMachineId,
      selectedCustomerId,
      showHidden,
    } = this.state;

    const customers = extractCustomersFromEvents(this.state.sortedEvents)
    const filteredEvents = filterEvents(this.state.sortedEvents, selectedEventType, selectedMachineId, showHidden, selectedCustomerId)

    const eventsAmount = () => {
      if (loadingEvents) {
        return (
          <div className="col">
            <span className="bold">LOADING...</span>
          </div>
        )
      } else {
        if (fullyLoaded) {
          return (
            <div className="col">
              <span className="bold">{filteredEvents.length}</span> events (in the last 4 months)
            </div>
          )
        } else {
          return (
            <div className="col">
              <span className="bold">{filteredEvents.length}</span> events (in the last month)
            </div>
          )
        }
      }
    }

    const showLoadingButton = () => {
      if (fullyLoaded) {
        return (
          <div className="center-text">
            <button type="button" className="btn btn-secondary mlr-5 fs-20" disabled>Fully Loaded</button>
          </div>
        )
      } else {
        return (
          <div className="center-text">
            <button type="button" className="btn btn-warning mlr-5 fs-20" onClick={this.getAllEvents}>Load All Events</button>
            <div className="fs-10">(...All? More like the last 4 months.)</div>
          </div>
        )
      }
    }

    const rowHeight = ({index}) => {
      let event = filteredEvents[index];
      let lines = Math.max(event.brand_names.length || 0, event.campaign_ids.length || 0, 1);
      return lines * 30;
    }

    const wrapInDivs = (strings) => {
      return (<>{strings.map(s => (<div key={s}>{s}</div>))}</>)
    }

    const rowClassName = ({ index }) => {
      if (index == -1) return "bg-extra-gray" // header
      else {
        const event = filteredEvents[index];
        if (event.isContextEvent) return "color-light-purple"
      }
    }

    const showEvents = () => {
      if (filteredEvents.length == 0) {
        return (
          <div className="center-text">
            <button type="button" className="btn btn-warning mlr-5 fs-20" onClick={this.getEvents}>Load Recent Events</button>
            <div className="fs-10">("Recent" (ie no older than 1 month))</div>
          </div>
        )
      } else {
        return (
            <AutoSizer disableWidth>
              {({height}) => (
                <Table
                  width={3345}
                  height={height}
                  headerHeight={40}
                  headerStyle={{minHeight: "40px", textAlign: "center", fontSize: "smaller"}}
                  rowHeight={rowHeight}
                  rowCount={filteredEvents.length}
                  rowGetter={({index}) => filteredEvents[index]}
                  rowRenderer={eventTableRowRenderer}
                  rowStyle={{alignItems: "start"}}
                  rowClassName={rowClassName}>
                  <Column label="Event Id" headerRenderer={renderHeader} dataKey="id" width={80} />
                  <Column label="Created At" headerRenderer={renderHeader} dataKey="created_at" width={250} />
                  <Column label="Event Type" headerRenderer={renderHeader} dataKey="event_type" width={300} />
                  <Column label="Visit Id" headerRenderer={renderHeader} dataKey="visit_id" width={80} />
                  <Column label="Customer Id" headerRenderer={renderHeader} dataKey="customer.id" cellDataGetter={({rowData}) => rowData.customer?.id} width={80} />
                  <Column label="Customer Name" headerRenderer={renderHeader} dataKey="customer.name" cellDataGetter={({rowData}) => rowData.customer?.name } width={150} />
                  <Column label="Customer Email" headerRenderer={renderHeader} dataKey="customer.email" cellDataGetter={({rowData}) => rowData.customer?.email } width={230} />
                  <Column label="Customer Phone" headerRenderer={renderHeader} dataKey="display_phone" cellDataGetter={({rowData}) => rowData.display_phone } width={110} />
                  <Column label="Machine Name" headerRenderer={renderHeader} dataKey="machine.name" cellDataGetter={({rowData}) => rowData.machine?.name } width={205} />
                  <Column label="Campaign Ids" headerRenderer={renderHeader} dataKey="campaign_ids" cellRenderer={({cellData}) => wrapInDivs(cellData)} width={80} />
                  <Column label="Brand Names" headerRenderer={renderHeader} dataKey="brand_names" cellRenderer={({cellData}) => wrapInDivs(cellData)} width={120} />
                  <Column label="Product Names" headerRenderer={renderHeader} dataKey="product.name" cellDataGetter={({rowData}) => rowData.product?.name } width={300} />
                  <Column label="Featured?" headerRenderer={renderHeader} dataKey="product.featured" cellDataGetter={({rowData}) => rowData.product?.featured } width={80} />
                  <Column label="Product Price" headerRenderer={renderHeader} dataKey="product.price" cellDataGetter={({rowData}) => rowData.product?.price } width={80} />
                  <Column label="PIN" headerRenderer={renderHeader} dataKey="pin" width={80} />
                  <Column label="PIN Status" headerRenderer={renderHeader} dataKey="pin_status" width={80} />
                  <Column label="Stock Status" headerRenderer={renderHeader} dataKey="stock_status" width={80} />
                  <Column label="Eligible Status" headerRenderer={renderHeader} dataKey="eligible_status" width={80} />
                  <Column label="Overstock Select" headerRenderer={renderHeader} dataKey="overstock_select" width={80} />
                  <Column label="Engagement Type" headerRenderer={renderHeader} dataKey="engagement_type" width={100} />
                  <Column label="Card Id" headerRenderer={renderHeader} dataKey="credit_card.id" cellDataGetter={({rowData}) => rowData.credit_card?.id } width={80} />
                  <Column label="Card Last4" headerRenderer={renderHeader} dataKey="credit_card.last4" cellDataGetter={({rowData}) => rowData.credit_card?.last4 } width={80} />
                  <Column label="Card Amount" headerRenderer={renderHeader} dataKey="credit_card.amount" cellDataGetter={({rowData}) => rowData.credit_card?.amount } width={80} />
                  <Column label="Card Status" headerRenderer={renderHeader} dataKey="credit_card.status" cellDataGetter={({rowData}) => rowData.credit_card?.status } width={300} />
                  <Column label="Rebate Quantity" headerRenderer={renderHeader} dataKey="rebate_quantity" cellDataGetter={({rowData}) => rowData.rebate_quantity } width={80} />
                  <Column label="Rebate Amount" headerRenderer={renderHeader} dataKey="rebate_amount" cellDataGetter={({rowData}) => rowData.rebate_amount } width={80} />
                  <Column label="Admin Hidden" headerRenderer={renderHeader} dataKey="admin_hidden" width={80} />
                </Table>
              )}
            </AutoSizer>
        )
      }
    }

    const allCustomersOption = { value: "all", label: "All Customers" }
    const customerOptions = customers.map(customer => {
      const label = [`#${customer.id}`, customer.name, customer.email, customer.phone].filter(Boolean).join(' ')
      return { value: customer.id, label }
    })
    const customersSelectOptions = [allCustomersOption, ...customerOptions]
    const selectedOption = customersSelectOptions.find(o => o.value == selectedCustomerId)

    const customerSelect = (
      <li className="nav-item">
        <div className="form-group">
          <label htmlFor="customer-select">Customers</label>
          {customersSelectOptions.length > 1 && (
            <Select
              inputId="customer-select"
              value={selectedOption}
              onChange={this.setSelectedCustomerId}
              options={customersSelectOptions} />)}
        </div>
      </li>
    )

    let pageName = 'machine_events_v2';

    return (
      <div className="container-fluid bg-extra-gray fs-14 admin">
        <AdminHeader
          logo={showdrop_logo}
          onDesktop={onDesktop}
          pageName={pageName}
          counts={this.props.counts}
        />

        <div className="row">
          <div className={`col col-lg-2 nav-restrict-size ${onDesktop ? '' : 'hide'}`}>
            <Nav
              active={pageName}
              counts={this.props.counts}
            />
          </div>

          <div className="col col-lg-10 bg-white o-auto d-flex flex-column vh-100">
            <IndexHeader
              name={'Raw Events V2'}
              url={null}
            />

            <ul className="nav nav-pills nav-justified">
              <li className="nav-item">
                <div className="form-group">
                  <label className="float-left">Event Types</label>
                  <select className="form-select" value={selectedEventType} onChange={this.filterEventsByType} name="event" disabled={loadingEvents ? 'disabled' : ''}>
                    <option key={`event-none`} value={null}>-</option>

                    {eventNames.map(event => {
                      return (
                        <option key={`event-${event}`} value={event}>{event}</option>
                      )
                    })}
                  </select>
                </div>

                <div className="form-group left-text pl-20">
                  <input type="checkbox" className="form-check-input" onChange={this.toggleHidden} checked={showHidden} disabled={loadingEvents ? 'disabled' : ''} />
                  <label>Show Hidden Events</label>
                </div>
              </li>

              <li className="nav-item">
                <div className="form-group">
                  <label className="float-left">Machines</label>
                  <select className="form-select" value={selectedMachineId} onChange={this.filterEventsByMachine} name="machine" disabled={loadingEvents ? 'disabled' : ''}>
                    <option value="" disabled selected></option>

                    {machines.map(machine => {
                      return (
                        <option key={`machine-${machine.id}`} value={machine.id}>{machine.nickname} [{machine.id}]</option>
                      )
                    })}
                  </select>
                </div>
              </li>

              {customerSelect}

              <li className="nav-item w40fs">
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 100, hide: 100 }}
                  overlay={renderTooltip("Download ALL Events")}
                >
                  <button type="button" className="btn btn-sm btn-primary mb-5px mt-30 fs-17 w35" onClick={this.downloadCsv} disabled={downloading ? "disabled" : ''}>
                    {
                      downloading ? (
                        <i className="fas fa-circle-notch fa-spin"></i>
                      ) : (
                        <i className="fas fa-file-download"></i>
                      )
                    }
                  </button>
                </OverlayTrigger>
              </li>

              <li className="nav-item w40fs">
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 100, hide: 100 }}
                  overlay={renderTooltip("Download Transaction Details")}
                >
                  <button type="button" className="btn btn-sm btn-warning mb-5px mt-30 fs-17 w35" onClick={this.downloadTransactionDetailsCsv} disabled={downloading ? "disabled" : ''}>
                    {
                      downloading ? (
                        <i className="fas fa-circle-notch fa-spin"></i>
                      ) : (
                        <i className="fas fa-desktop"></i>
                      )
                    }
                  </button>
                </OverlayTrigger>
              </li>

              <li className="nav-item w40fs">
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 100, hide: 100 }}
                  overlay={renderTooltip("Download Machine Logs")}
                >
                  <button type="button" className="btn btn-sm btn-info mb-5px mt-30 fs-17 w35" onClick={this.downloadMachineLogCsv} disabled={downloading ? "disabled" : ''}>
                    {
                      downloading ? (
                        <i className="fas fa-circle-notch fa-spin"></i>
                      ) : (
                        <i className="fas fa-history"></i>
                      )
                    }
                  </button>
                </OverlayTrigger>
              </li>
            </ul>

            <div className="row pb-10">
              {eventsAmount()}
            </div>

            { loadingEvents ? (
                <div className="m-auto w100p o-x-scroll h1000 center-text">
                  <i className="fas fa-circle-notch fa-spin fs-80"></i>
                </div>
              ) : (
                <>
                  <div className="row">
                    {showLoadingButton()}
                  </div>
                  <div className="row flex-grow-1">
                    {showEvents()}
                  </div>
                </>
              )
            }

          </div>
        </div>
      </div>
    )
  }
}

function filterEvents(
  events,
  selectedEventType,
  selectedMachineId,
  showHidden,
  selectedCustomerId) {

  return filterEventsByCustomerVisit(events, selectedCustomerId).filter(event => {
    return (selectedEventType == '-' || event.event_type == selectedEventType) &&
           (selectedMachineId == '' || event.machine.id == selectedMachineId) &&
           (showHidden || !event.admin_hidden)
  })
}

/*
 * filter events to include
 * 1) any events with customer.id equal to selectedCustomerId
 * 2) any events with visit_it equal to any visit_id associated with events from 1
 * 3) any events from the same machine as 1 & 2 that occured up to 5 minutes prior
 *
 * This method depends on events being sorted in reverse chronological order (newest events first)
 */
function filterEventsByCustomerVisit(
  events,
  selectedCustomerId) {

  if (selectedCustomerId == "all") return events
  const initialValue = {
    customerEvents: [],
    visitIds: new Set(),
    machineContextLowerBound: {}
  }
  const result = events.reduce((acc, event) => {
    const isCustomerEvent = (event.customer && event.customer.id == selectedCustomerId) || acc.visitIds.has(event.visit_id)
    if (isCustomerEvent) {
      acc.customerEvents.push(event)
      if (event.visit_id) acc.visitIds.add(event.visit_id)
      if (event.machine.id) acc.machineContextLowerBound[event.machine.id] = Date.parse(event.created_at) - (5 * 60 * 1000)
    } else if (event.machine.id && event.machine.id in acc.machineContextLowerBound) {
      const lowerBound = acc.machineContextLowerBound[event.machine.id]
      const eventCreatedAt = Date.parse(event.created_at)
      if (eventCreatedAt > lowerBound) {
        let contextEvent = { ...event, isContextEvent: true }
        acc.customerEvents.push(contextEvent)
      }
    }
    return acc
  }, initialValue)
  return result.customerEvents;
}

function extractCustomersFromEvents(events) {
  if (events === undefined || events.length === 0) return []
  const customersById = events.reduce((acc, e) => !e.customer?.id || e.customer.id in acc ? acc : {...acc, [e.customer.id]: e.customer}, {});
  return Object.values(customersById).sort((a, b) => a.id - b.id)
}

function renderHeader({label}) {
  return (
    <span
      style={{
        display: "inline-block",
        maxWidth: "100%",
        whiteSpace: "normal",
        textOverflow: "ellipsis",
        overflow: "hidden",
      }}
      key="label"
      title={typeof label === 'string' ? label : null}>
      {typeof label === 'string' ? label.match(/[^\s,]+/g).flatMap(w => [w, <br/>]) : label}
    </span>
  );
}

function renderTooltip(text) {
  return (
    <Tooltip id="button-tooltip">{text}</Tooltip>
  )
};

function eventTableRowRenderer(props) {
  const key = props.rowData.id
  return defaultTableRowRenderer({...props, key})
}
