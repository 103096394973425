import React from "react";
import ahoy from "ahoy.js";
import Select from "react-select";

export default class Text extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      firstName: "",
      loading: false,
      message: "",
      phone: "",
      phoneSubmitted: false,
      selectedGender: "",
      selectedYear: "",
      showConventionsForm: false,
      showExpoWestForm: false,
      showForm: false,
      showThanks: false,
      submitUrl: "",
      success: false,
      zipcode: "",
    };

    this.changeEmail = this.changeEmail.bind(this)
    this.changeFirstName = this.changeFirstName.bind(this)
    this.changeGender = this.changeGender.bind(this)
    this.changePhone = this.changePhone.bind(this)
    this.changeYear = this.changeYear.bind(this)
    this.changeZipCode = this.changeZipCode.bind(this)
    this.createRecord = this.createRecord.bind(this)
    this.updateRecord = this.updateRecord.bind(this)
  }

  changeGender(event) {
    let genderId = Number(event.value);

    this.setState({
      selectedGender: genderId,
    })
  }

  changePhone({ target: { value } }) {
    this.setState(prevState => ({
      phone: normalizeInput(value, prevState.phone)
    }));
  }

  changeEmail(event) {
    this.setState({
      email: event.target.value,
    })
  }

  changeFirstName(event) {
    this.setState({
      firstName: event.target.value,
    })
  }

  changeYear(event) {
    this.setState({
      selectedYear: event.value,
    })
  }

  changeZipCode(event) {
    let zip = event.target.value.replace(/[^\d]/g, '').slice(0, 5)

    this.setState({
      zipcode: zip
    })
  }

  createRecord() {
    let that = this;

    this.setState({
      loading: true,
      phoneSubmitted: true,
      message: "",
    })

    const formData = new FormData();
    let data = JSON.stringify({
      phone: this.state.phone,
    })
    formData.append('customer', data)

    fetch(this.props.url_text, {
      method: 'POST',
      headers: {},
      body: formData
    }).then(response => {
      return response.json();
    }).then((data) => {
      if (data.errors) {
        that.setState({
          message: data.errors,
          loading: false,
          phoneSubmitted: false
        })
      } else {
        if (data.form) {
          that.setState({
            loading: false,
            showExpoWestForm: data.expo_west_form,
            showConventionsForm: data.conventions_form,
            showForm: true,
            submitUrl: data.url,
          })
        } else {
          const formData = new FormData();
          let formParams = JSON.stringify({
            phone: data.phone,
          })
          formData.append('customer', formParams)

          fetch(data.url, {
            method: 'POST',
            headers: {},
            body: formData
          }).then(response => {
            return response.json();
          }).then((data) => {
            if (data.errors) {
              that.setState({
                message: data.errors,
                loading: false,
                phoneSubmitted: false,
              })
            } else {
              that.setState({
                loading: false,
                message: data.message,
                phone: '',
                showExpoWestForm: false,
                showConventionsForm: false,
                showForm: false,
                showThanks: false,
                success: true,
              })
            }
          })
        }
      }
    })
  }

  updateRecord() {
    let that = this;

    this.setState({
      loading: true,
    })

    const formData = new FormData();
    let data = JSON.stringify({
      dob: this.state.selectedYear,
      email: this.state.email,
      first_name: this.state.firstName,
      gender: this.state.selectedGender,
      phone: this.state.phone,
      zip_code: this.state.zipcode,
    })
    formData.append('customer', data)

    fetch(this.state.submitUrl, {
      method: 'POST',
      headers: {},
      body: formData
    }).then(response => {
      return response.json();
    }).then((data) => {
      if (data.errors) {
        that.setState({
          message: data.errors,
          loading: false
        })
      } else {
        that.setState({
          showForm: false,
          showExpoWestForm: false,
          showConventionsForm: false,
          showThanks: true,
          message: data.message,
          phone: '',
          email: '',
          success: true,
          loading: false
        })
      }
    })
  }

  render() {
    let {
      genders,
      header_image,
      machine,
      showdrop_logo,
      url_privacy,
      url_terms,
      years,
    } = this.props;
    let {
      email,
      firstName,
      loading,
      message,
      phone,
      phoneSubmitted,
      selectedGender,
      selectedYear,
      showConventionsForm,
      showExpoWestForm,
      showForm,
      showThanks,
      success,
      zipcode,
    } = this.state;

    let footer = () => {
      return (
        <div className="fixed-bottom bg-dark-gray" style={{ textAlign:"center" }}>
          <img className="w250" src={showdrop_logo} />
        </div>
      )
    }
    let questionnaireStyles = {
      control: (base, _) => ({
        ...base,
        background: "#c7c7c7",
        height: "46px"
      }),
      option: (styles, {isFocused, isSelected}) => ({
        ...styles,
        background: isFocused
            ? "#20cb9a50"
            : isSelected
                ? "#20cb9a"
                : undefined,
        zIndex: 1
      }),
      menu: base => ({
        ...base,
        borderRadius: 0,
        marginTop: 0
      }),
      menuList: base => ({
        ...base,
        padding: 0
      })
    };

    let questionnaire = () => {
      return (
        <div className="h1000">
          <div className="container pb-40 pt-100">
            <div className="row align-items-center justify-content-center">
              <div className="col-10 col-md-6 col-lg-4 pt-30">
                <h2 className="color-primary center-text fs-2rem bold">
                  3 quick questions before you sample!
                </h2>
              </div>
            </div>

            <div className="row align-items-center justify-content-center">
              <div className="col-10 col-md-6 col-lg-4 pt-30">
                <h3 className="color-white fs-16 pt-10 bold pdb-5">What's your birth year?</h3>
              </div>
            </div>

            <div className="row align-items-center justify-content-center">
              <div className="col-10 col-md-6 col-lg-4">
                <div className="form-landing">
                  <Select options={years} onChange={this.changeYear} styles={questionnaireStyles} />
                </div>
              </div>
            </div>

            <div className="row align-items-center justify-content-center">
              <div className="col-10 col-md-6 col-lg-4 pt-30">
                <h3 className="color-white fs-16 pt-10 bold pdb-5">What's your gender?</h3>
              </div>
            </div>

            <div className="row align-items-center justify-content-center">
              <div className="col-10 col-md-6 col-lg-4">
                <div className="form-landing">
                  <Select options={genders} onChange={this.changeGender} styles={questionnaireStyles} />
                </div>
              </div>
            </div>

            <div className="row align-items-center justify-content-center">
              <div className="col-10 col-md-6 col-lg-4 pt-30">
                <h3 className="color-white fs-16 pt-10 bold pdb-5">What's your US zipcode?</h3>
              </div>
            </div>

            <div className="row align-items-center justify-content-center">
              <div className="col-10 col-md-6 col-lg-4">
                <div className="form-landing">
                  <input className="form-control bg-semi-light-gray border-semi-light-gray color-dark-gray br-4" placeholder="XXXXX" pattern="[0-9]{1,5}" onChange={this.changeZipCode} value={zipcode} />
                </div>
              </div>
            </div>

            <div className="row align-items-center justify-content-center">
              <div className="col-10 col-md-6 col-lg-4 pt-30">
                <div className="form-landing">
                  {questionnaireSampleButton()}
                </div>
              </div>
            </div>

            <RenderSuccess
              message={message}
              success={success}
            />
          </div>

          {footer()}
        </div>
      )
    }

    let questionnaireSampleButton = () => {
      if ((!(selectedGender === "")) && selectedYear && (zipcode.length == 5)) {
        if (loading) {
          return (
            <button className="btn btn-sm btn-block color-white bold bg-showdrop br-4" style={{ padding:"10px",margin:"10px 0",fontSize:"24px" }} type="submit" disabled="disabled">
              <i className="fas fa-circle-notch fa-spin"></i>
            </button>
          )
        } else {
          return (
            <button className="btn btn-sm btn-block color-white bold bg-showdrop br-4" style={{ padding:"10px",margin:"10px 0",fontSize:"24px" }} type="submit" onClick={this.updateRecord}>
              Get my sample!
            </button>
          )
        }
      } else {
        if (loading) {
          return (
            <button className="btn btn-sm btn-block color-white bold bg-light-gray br-4" style={{ padding:"10px",margin:"10px 0",fontSize:"24px" }} type="submit" disabled="disabled">
              <i className="fas fa-circle-notch fa-spin"></i>
            </button>
          )
        } else {
          return (
            <button className="btn btn-sm btn-block color-white bold bg-light-gray br-4" style={{ padding:"10px",margin:"10px 0",fontSize:"24px" }} type="submit" disabled="disabled">
              Get my sample!
            </button>
          )
        }
      }
    }

    let expoWestQuestionnaire = () => {
      return (
        <div className="h1000">
          <div className="container pb-40 pt-100">
            <div className="row align-items-center justify-content-center">
              <div className="col-10 col-md-6 col-lg-4 pt-30">
                <h2 className="color-primary center-text fs-2rem bold">
                  2 quick questions before you sample!
                </h2>
              </div>
            </div>

            <div className="row align-items-center justify-content-center">
              <div className="col-10 col-md-6 col-lg-4 pt-30">
                <h3 className="color-white fs-16 pt-10 bold pdb-5">What's your first name?</h3>
              </div>
            </div>

            <div className="row align-items-center justify-content-center">
              <div className="col-10 col-md-6 col-lg-4">
                <div className="form-landing">
                  <input className="form-control bg-semi-light-gray border-semi-light-gray color-dark-gray br-4" onChange={this.changeFirstName} value={firstName} />
                </div>
              </div>
            </div>

            <div className="row align-items-center justify-content-center">
              <div className="col-10 col-md-6 col-lg-4 pt-30">
                <h3 className="color-white fs-16 pt-10 bold pdb-5">What's your US zipcode?</h3>
              </div>
            </div>

            <div className="row align-items-center justify-content-center">
              <div className="col-10 col-md-6 col-lg-4">
                <div className="form-landing">
                  <input className="form-control bg-semi-light-gray border-semi-light-gray color-dark-gray br-4" placeholder="XXXXX" pattern="[0-9]{1,5}" onChange={this.changeZipCode} value={zipcode} />
                </div>
              </div>
            </div>

            <div className="row align-items-center justify-content-center">
              <div className="col-10 col-md-6 col-lg-4 pt-30">
                <div className="form-landing">
                  {expoWestQuestionnaireSampleButton()}
                </div>
              </div>
            </div>

            <RenderSuccess
              message={message}
              success={success}
            />
          </div>

          {footer()}
        </div>
      )
    }

    let expoWestQuestionnaireSampleButton = () => {
      if (firstName && (zipcode.length == 5)) {
        if (loading) {
          return (
            <button className="btn btn-sm btn-block color-white bold bg-showdrop br-4" style={{ padding:"10px",margin:"10px 0",fontSize:"24px" }} type="submit" disabled="disabled">
              <i className="fas fa-circle-notch fa-spin"></i>
            </button>
          )
        } else {
          return (
            <button className="btn btn-sm btn-block color-white bold bg-showdrop br-4" style={{ padding:"10px",margin:"10px 0",fontSize:"24px" }} type="submit" onClick={this.updateRecord}>
              Get my sample!
            </button>
          )
        }
      } else {
        if (loading) {
          return (
            <button className="btn btn-sm btn-block color-white bold bg-light-gray br-4" style={{ padding:"10px",margin:"10px 0",fontSize:"24px" }} type="submit" disabled="disabled">
              <i className="fas fa-circle-notch fa-spin"></i>
            </button>
          )
        } else {
          return (
            <button className="btn btn-sm btn-block color-white bold bg-light-gray br-4" style={{ padding:"10px",margin:"10px 0",fontSize:"24px" }} type="submit" disabled="disabled">
              Get my sample!
            </button>
          )
        }
      }
    }

    let conventionsQuestionnaire = () => {
      return (
        <div className="h1000">
          <div className="container pb-40 pt-100">
            <div className="row align-items-center justify-content-center">
              <div className="col-10 col-md-6 col-lg-4 pt-30">
                <h2 className="color-primary center-text fs-2rem bold">
                  1 quick question before you sample!
                </h2>
              </div>
            </div>

            <div className="row align-items-center justify-content-center">
              <div className="col-10 col-md-6 col-lg-4 pt-30">
                <h3 className="color-white fs-16 pt-10 bold pdb-5">What's your email?</h3>
              </div>
            </div>

            <div className="row align-items-center justify-content-center">
              <div className="col-10 col-md-6 col-lg-4">
                <div className="form-landing">
                  <input type="email" className="form-control bg-semi-light-gray border-semi-light-gray color-dark-gray br-4" onChange={this.changeEmail} value={email} />
                </div>
              </div>
            </div>

            <div className="row align-items-center justify-content-center">
              <div className="col-10 col-md-6 col-lg-4 pt-30">
                <div className="form-landing">
                  {conventionsQuestionnaireSampleButton()}
                </div>
              </div>
            </div>

            <RenderSuccess
              message={message}
              success={success}
            />
          </div>

          {footer()}
        </div>
      )
    }

    let conventionsQuestionnaireSampleButton = () => {
      if (email && email.includes('@') && email.includes('.')) {
        if (loading) {
          return (
            <button className="btn btn-sm btn-block color-white bold bg-showdrop br-4" style={{ padding:"10px",margin:"10px 0",fontSize:"24px" }} type="submit" disabled="disabled">
              <i className="fas fa-circle-notch fa-spin"></i>
            </button>
          )
        } else {
          return (
            <button className="btn btn-sm btn-block color-white bold bg-showdrop br-4" style={{ padding:"10px",margin:"10px 0",fontSize:"24px" }} type="submit" onClick={this.updateRecord}>
              Get my sample!
            </button>
          )
        }
      } else {
        if (loading) {
          return (
            <button className="btn btn-sm btn-block color-white bold bg-light-gray br-4" style={{ padding:"10px",margin:"10px 0",fontSize:"24px" }} type="submit" disabled="disabled">
              <i className="fas fa-circle-notch fa-spin"></i>
            </button>
          )
        } else {
          return (
            <button className="btn btn-sm btn-block color-white bold bg-light-gray br-4" style={{ padding:"10px",margin:"10px 0",fontSize:"24px" }} type="submit" disabled="disabled">
              Get my sample!
            </button>
          )
        }
      }
    }

    let thanks = () => {
      return (
        <div className="h1000">
          <div className="container pb-40 pt-100">
            <div className="row align-items-center justify-content-center">
              <div className="col-10 col-md-6 col-lg-4 pt-30">
                <h1 className="color-primary fs-3rem bold lh-1">
                  Hello & Welcome
                </h1>
              </div>
            </div>

            <div className="row align-items-center justify-content-center">
              <div className="col-10 col-md-6 col-lg-4 pt-20">
                <h3 className="color-primary fs-175rem lh-1">
                  You're on your way to sampling greatness
                </h3>
              </div>
            </div>

            <div className="row align-items-center justify-content-center">
              <div className="col-10 col-md-6 col-lg-4 pt-20">
                <p className="color-white fs-125rem lh-1">
                  Check your phone for a PIN that you can input into the kiosk for your free sample!
                </p>
              </div>
            </div>

            <div className="row align-items-center justify-content-center">
              <div className="col-10 col-md-6 col-lg-4">
                <p className="color-white fs-125rem lh-1">
                  You will get a new PIN each time you sample.
                </p>
              </div>
            </div>

            <div className="row align-items-center justify-content-center">
              <div className="col-10 col-md-6 col-lg-4">
                <p className="color-white fs-125rem lh-1">
                  Reach out to info@showdrop.com if you have any issues.
                </p>
              </div>
            </div>

            <div className="row align-items-center justify-content-center">
              <div className="col-10 col-md-6 col-lg-4 pt-30">
                <a href="https://www.instagram.com/showdrop/" target="_blank" style={{ display:"flex",textDecoration:"underline",color:"white" }}>
                  <div>
                    <img src="https://upload.wikimedia.org/wikipedia/commons/e/e7/Instagram_logo_2016.svg" style={{ width:"50px" }}></img>
                  </div>

                  <div style={{ paddingLeft:"10px",lineHeight:"1",fontSize:"16px" }}>
                    <p style={{ margin:"0",paddingTop:"7px" }}>Follow us on Instagram</p>
                    <p style={{ margin:"0" }}>@showdrop</p>
                  </div>
                </a>
              </div>
            </div>
          </div>

          {footer()}
        </div>
      )
    }

    let imageHeader = () => {
      if (header_image) {
        return (
          <div className="row align-items-center justify-content-center">
            <div className="col" style={{ padding:"0",backgroundColor:"white",textAlign:"center" }}>
              <img style={{ padding:"20px 30px",maxHeight:"150px" }} src={header_image}></img>
            </div>
          </div>
        )
      } else {
        return null;
      }
    }

    if (showExpoWestForm) {
      return expoWestQuestionnaire()
    } else if (showConventionsForm) {
      return conventionsQuestionnaire()
    } else if (showForm) {
      return questionnaire()
    } else if (showThanks) {
      return thanks()
    } else {
      return (
        <div className="h1000">
          <div className={`container pb-40 ${header_image ? "pt-50" : "pt-100"}`}>
            {imageHeader()}

            <div className="row align-items-center justify-content-center">
              <div className="col-10 col-md-6 col-lg-4 pt-30">
                <h2 className="color-primary center-text fs-15rem bold">
                  Enter your phone number below for PIN to unlock your free sample
                </h2>
              </div>
            </div>

            <div className="row align-items-center justify-content-center">
              <div className="col-10 col-md-6 col-lg-4 pt-30">
                <h3 className="color-white pt-10 bold pdb-5" style={{ lineHeight:"1.4",fontSize:"13px" }}>
                  We ask for your phone number to regulate sampling but will not share your number with any third parties
                </h3>
              </div>
            </div>

            <div className="row align-items-center justify-content-center">
              <div className="col-10 col-md-6 col-lg-4">
                <div className="form-landing">
                  <label className="sr-only" for="customer_phone">Phone Number</label>

                  <div className="d-flex">
                    <button className="btn btn-sm btn-block bg-semi-light-gray border-semi-light-gray color-dark-gray bold w40 br-l-4" onClick={null}>
                      +1
                    </button>

                    <input type="tel" className="form-control bg-semi-light-gray border-semi-light-gray color-dark-gray br-r-4 bold" placeholder="(XXX) XXX-XXXX" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" onChange={this.changePhone} value={phone} />

                    {
                      phone && phone.length == 14 ? (
                        <button className="btn btn-sm btn-block color-white bold w50" type="submit" onClick={phoneSubmitted || loading ? null : this.createRecord} disabled={phoneSubmitted || loading ? 'disabled' : ''} ref={(node) => {
                          if (node) {
                            node.style.setProperty("background-color", "#20cb9a", "important")
                          }
                        }}>
                          <i className="fas fa-angle-double-right fs-20"></i>
                        </button>
                      ) : (
                        <button className="btn btn-sm btn-block color-white bold w50" type="submit" disabled='disabled' ref={(node) => {
                          if (node) {
                            node.style.setProperty("background-color", "#a8a8a8", "important")
                          }
                        }}>
                          <i className="fas fa-angle-double-right fs-20"></i>
                        </button>
                      )
                    }
                  </div>
                </div>
              </div>
            </div>

            <RenderSuccess
              message={message}
              success={success}
            />

            <div className="row align-items-center justify-content-center pt-10">
              <div className="col-10 col-md-8 col-lg-6">
                <p className="center-text color-light-gray fs-10 italic">
                  <span className="pdr-2">By using Showdrop, you agree to our</span>
                  <a className="color-primary" href={url_terms} target="_blank">Terms of Service</a>
                  <span className="pdlr-2">and</span>
                  <a className="color-primary" href={url_privacy} target="_blank">Privacy Policy</a>.
                </p>
              </div>
            </div>

          </div>

          {footer()}
        </div>
      );
    }
  }
}

function normalizeInput(value, _) {
  if (!value) return value;
  const currentValue = value.replace(/[^\d]/g, '');
  const cvLength = currentValue.length;

  if (cvLength < 4) return currentValue;
  if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
  return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
};

function RenderSuccess(props) {
  let { message, success } = props;

  const successMessage = () => {
    if (success) {
      return (
        <p className="bold fs-12 color-green">{message}</p>
      )
    } else {
      return (
        <p className="bold fs-12 color-red">{message}</p>
      )
    }
  }

  if (message) {
    return (
      <div className="row align-items-center justify-content-center center-text">
        <div className="col-10 col-md-6 col-lg-4 pt-10">
          {successMessage()}
        </div>
      </div>
    )
  } else {
    return null;
  }
}
