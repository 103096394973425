import React from "react"
import Nav from "../shared/Nav";
import IndexHeader from "../shared/headers/IndexHeader";
import AdminHeader from "../shared/headers/AdminHeader";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export default class Index extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      downloading: false,
      onDesktop: true
    }

    this.downloadCsv = this.downloadCsv.bind(this)
  }

  componentDidMount() {
    let onDesktop = window && window.innerWidth >= 1000;

    this.setState({
      onDesktop: onDesktop
    })
  }

  downloadCsv() {
    let that = this;
    that.setState({
      downloading: true
    })

    fetch(this.props.url_download_csv, {
      method: 'GET',
      headers: {}
    }).then(response => {
      return response.blob();
    }).then((blob) => {
      that.setState({
        downloading: false
      })
      const url = window.URL.createObjectURL(
        new Blob([blob]),
      );
      const link = document.createElement('a');
      link.href = url;
      var today = new Date(),
      date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

      link.setAttribute(
        'download',
        `customers_${date}.csv`,
      );

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    })
  }

  render () {
    let { customers, showdrop_logo } = this.props;
    let { downloading, onDesktop } = this.state;
    let pageName = 'customers';

    return (
      <div className="container-fluid bg-extra-gray fs-14">
        <AdminHeader
          logo={showdrop_logo}
          onDesktop={onDesktop}
          pageName={pageName}
          counts={this.props.counts}
        />

        <div className="row full-height">
          <div className={`col col-lg-2 nav-restrict-size ${onDesktop ? '' : 'hide'}`}>
            <Nav
              active={pageName}
              counts={this.props.counts}
            />
          </div>

          <div className="col col-lg-10 bg-white h800 o-auto mobile-block">
            <IndexHeader
              name={'Customers'}
              url={null}
            />

            <div className="row pb-10 pos-r">
              <div className="col pos-a">
                <span className="bold">{customers.length}</span> customers
              </div>

              <div className="col">
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 100, hide: 100 }}
                  overlay={renderTooltip("Download Customers")}
                >
                  <button type="button" className="btn btn-sm btn-success mb-5px fs-17 w35 floatr" onClick={this.downloadCsv} disabled={downloading ? "disabled" : ''}>
                    {
                      downloading ? (
                        <i className="fas fa-circle-notch fa-spin"></i>
                      ) : (
                        <i className="fas fa-download"></i>
                      )
                    }
                  </button>
                </OverlayTrigger>
              </div>
            </div>

            <div className="row">
              <div className="col col-lg-4 bg-white">
                <div className="table-responsive">
                  <table className="table table-sm">
                    <tr>
                      <td>
                        <i className="fas fa-mobile color-primary pdr-5 fs-16 w30 center-text"></i>
                        App User
                        <div className="fs-8">Customers with mobile app</div>
                      </td>

                      <td>{this.props.customers_with_app_percent}</td>
                    </tr>

                    <tr>
                      <td>
                        <i className="fas fa-check-circle color-primary pdr-5 fs-16 w30 center-text"></i>
                        Email Verified
                        <div className="fs-8">Mobile app customers with verified emails</div>
                      </td>

                      <td>{this.props.customers_verified_email_percent}</td>
                    </tr>

                    <tr>
                      <td>
                        <i className="fas fa-envelope color-primary pdr-5 fs-16 w30 center-text"></i>
                        Share Email
                        <div className="fs-8">Mobile app customers that allow share emails</div>
                      </td>

                      <td>{this.props.customers_share_email_percent}</td>
                    </tr>

                    <tr>
                      <td>
                        <i className="fas fa-sms color-primary pdr-5 fs-16 w30 center-text"></i>
                        Text Opt In
                        <div className="fs-8">Customers with phone # that didnt opt out</div>
                      </td>

                      <td>{this.props.customers_opt_in_percent}</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>

            <table className="table">
              <thead>
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">Status</th>
                  <th scope="col">Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Phone</th>
                  <th scope="col">Birth Year</th>
                  <th scope="col">Gender</th>
                  <th scope="col">Zip Code</th>
                  <th scope="col">Last Engagement</th>
                  <th scope="col">Last Machine</th>
                </tr>
              </thead>
              <tbody>
                {customers.map(customer => {
                  return (
                    <tr>
                      <td>{customer.id}</td>
                      <td>
                        <div style={{ display:"flex" }}>
                          <div style={{ flex:"1" }}>
                            {
                              customer.email_verified ? (
                                <i className="fas fa-check-circle color-primary pdr-5 fs-20"></i>
                              ) : (
                                <i className="far fa-check-circle color-extra-light-gray pdr-5 fs-20"></i>
                              )
                            }
                          </div>

                          <div style={{ flex:"1" }}>
                            {
                              customer.share_email ? (
                                <i className="fas fa-envelope color-primary pdr-5 fs-20"></i>
                              ) : (
                                <i className="far fa-envelope color-extra-light-gray pdr-5 fs-20"></i>
                              )
                            }
                          </div>

                          <div style={{ flex:"1" }}>
                            {
                              customer.twilio_opt_out ? (
                                <i className="fas fa-sms color-extra-light-gray pdr-5 fs-20"></i>
                              ) : (
                                <i className="fas fa-sms color-primary pdr-5 fs-20"></i>
                              )
                            }
                          </div>

                          <div style={{ flex:"1" }}>
                            {
                              customer.mobile_user ? (
                                <i className="fas fa-mobile color-primary pdr-5 fs-20"></i>
                              ) : (
                                <i className="fas fa-mobile-alt color-extra-light-gray pdr-5 fs-20"></i>
                              )
                            }
                          </div>
                        </div>
                      </td>
                      <td>{customer.name || '-'}</td>
                      <td>{customer.email || '-'}</td>
                      <td>{customer.phone || '-'}</td>
                      <td>{customer.birth_year || '-'}</td>
                      <td>{customer.gender || '-'}</td>
                      <td>{customer.zip_code || '-'}</td>
                      <td>{customer.last_engagament_date || '-'}</td>
                      <td>{customer.last_machine || '-'}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

function renderTooltip(text) {
  return (
    <Tooltip id="button-tooltip">{text}</Tooltip>
  )
};
