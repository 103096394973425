import React from "react"
import Nav from "../shared/Nav";
import EditHeader from "../shared/headers/EditHeader";
import AdminHeader from "../shared/headers/AdminHeader";
import Errors from "../shared/Errors";

export default class Edit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      api: this.props.machine.non_api,
      automatic: this.props.machine.automatic,
      email: this.props.machine.email,
      errors: '',
      longitude: this.props.machine.longitude,
      latitude: this.props.machine.latitude,
      location: this.props.machine.location,
      name: this.props.machine.name,
      nickname: this.props.machine.nickname,
      notes: this.props.machine.notes,
      onDesktop: true,
      password: this.props.machine.password,
      raMachineId: this.props.machine.ra_machine_id,
      selectedCompanyId: this.props.machine.company_id,
      zipcode: this.props.machine.zipcode,
    };

    this.changeApi = this.changeApi.bind(this)
    this.changeAutomatic = this.changeAutomatic.bind(this)
    this.changeCompanyId = this.changeCompanyId.bind(this)
    this.changeEmail = this.changeEmail.bind(this)
    this.changeLatitude = this.changeLatitude.bind(this)
    this.changeLocation = this.changeLocation.bind(this)
    this.changeLongitude = this.changeLongitude.bind(this)
    this.changeName = this.changeName.bind(this)
    this.changeNickname = this.changeNickname.bind(this)
    this.changeNotes = this.changeNotes.bind(this)
    this.changePassword = this.changePassword.bind(this)
    this.changeRaMachineId = this.changeRaMachineId.bind(this)
    this.changeZipcode = this.changeZipcode.bind(this)
    this.editRecord = this.editRecord.bind(this)
  }

  componentDidMount() {
    let onDesktop = window && window.innerWidth >= 1000;

    this.setState({
      onDesktop: onDesktop
    })
  }

  changeApi() {
    let api = !this.state.api;

    this.setState({
      api: api
    })
  }

  changeAutomatic() {
    let automatic = !this.state.automatic;

    this.setState({
      automatic: automatic
    })
  }

  changeCompanyId(event) {
    this.setState({
      selectedCompanyId: event.target.value,
    })
  }

  changeEmail(event) {
    this.setState({
      email: event.target.value
    })
  }

  changeLatitude(event) {
    this.setState({
      latitude: event.target.value
    })
  }

  changeLocation(event) {
    this.setState({
      location: event.target.value
    })
  }

  changeLongitude(event) {
    this.setState({
      longitude: event.target.value
    })
  }

  changeName(event) {
    this.setState({
      name: event.target.value
    })
  }

  changeNickname(event) {
    this.setState({
      nickname: event.target.value
    })
  }

  changeNotes(event) {
    this.setState({
      notes: event.target.value
    })
  }

  changePassword(event) {
    this.setState({
      password: event.target.value
    })
  }

  changeRaMachineId(event) {
    this.setState({
      raMachineId: event.target.value
    })
  }

  changeZipcode(event) {
    this.setState({
      zipcode: event.target.value
    })
  }

  editRecord() {
    let that = this;
    const formData = new FormData();
    let data = JSON.stringify({
      automatic: this.state.automatic,
      company_id: this.state.selectedCompanyId,
      email: this.state.email,
      latitude: this.state.latitude,
      location: this.state.location,
      longitude: this.state.longitude,
      name: this.state.name,
      nickname: this.state.nickname,
      notes: this.state.notes,
      non_api: this.state.api,
      password: this.state.password,
      ra_machine_id: this.state.raMachineId,
      zipcode: this.state.zipcode,
    })
    formData.append('machine', data)

    fetch(this.props.machine.edit_path, {
      method: 'PUT',
      headers: {},
      body: formData
    }).then(response => {
      return response.json();
    }).then((data) => {
      if (data.errors) {
        that.setState({
          errors: data.errors
        })
      } else {
        window.location.assign(data.url)
      }
    })
  }

  endCampaign(id, url, machine_id) {
    if( confirm('Are you sure you want to end this campaign?') ) {
      let data = JSON.stringify({
        id: id,
        machine_id: machine_id
      })

      fetch(url, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: data
      }).then(response => {
        return response.json();
      }).then(data => {
        window.location.assign(data.url)
      })
    }
  }

  render () {
    let { companies, machine, showdrop_logo, url_new } = this.props;
    let {
      api,
      automatic,
      email,
      errors,
      latitude,
      location,
      longitude,
      name,
      nickname,
      notes,
      onDesktop,
      password,
      raMachineId,
      selectedCompanyId,
      zipcode,
    } = this.state;
    let pageName = 'configure_machine'

    return (
      <div className="container-fluid bg-extra-gray fs-14">
        <AdminHeader
          logo={showdrop_logo}
          onDesktop={onDesktop}
          pageName={pageName}
          counts={this.props.counts}
        />

        <div className="row full-height">
          <div className={`col col-lg-2 nav-restrict-size ${onDesktop ? '' : 'hide'}`}>
            <Nav
              active={pageName}
              counts={this.props.counts}
            />
          </div>

          <div className="col bg-white">
            <EditHeader
              name={machine.name}
              url_show={machine.url}
              url_new={url_new}
            />

            <dl className="row">
              <dt className="col-sm-3 text-right">
                Nickname
                <div className="fs-10 thin">(Internal Only)</div>
              </dt>
              <dd className="col-sm-9">
                <div className="input-group mb-3">
                  <input type="text" className="form-control" placeholder="Nickname" onChange={this.changeNickname} value={nickname} />
                </div>
              </dd>

              <dt className="col-sm-3 text-right">
                Name
                <div className="fs-10 thin">(Customer Facing)</div>
              </dt>
              <dd className="col-sm-9">
                <div className="input-group mb-3">
                  <input type="text" className="form-control" placeholder="Name" onChange={this.changeName} value={name} />
                </div>
              </dd>

              <dt className="col-sm-3 text-right">
                Company
                <div className="fs-10 thin">(Optional: This allows for a Company Portal vs a Machine Portal)</div>
              </dt>
              <dd className="col-sm-9">
                <select className="form-select" value={selectedCompanyId} onChange={this.changeCompanyId}>
                  <option value="" selected>Select a Company ID</option>

                  {companies.map(company => {
                    return (
                      <option key={company.id} value={company.id}>{company.name}</option>
                    )
                  })}
                </select>
              </dd>

              <dt className="col-sm-3 text-right">
                Location
                <div className="fs-10 thin">Address of Machine (Used for Mobile)(Use "|" when you want a new line)</div>
              </dt>
              <dd className="col-sm-9">
                <div className="input-group mb-3">
                  <textarea type="text" className="form-control" placeholder="Location" onChange={this.changeLocation} value={location} />
                </div>
              </dd>

              <dt className="col-sm-3 text-right">
                Zipcode
                <div className="fs-10 thin">Used for geolocation, default is Chicago</div>
              </dt>
              <dd className="col-sm-9">
                <div className="input-group mb-3">
                  <input type="text" className="form-control" placeholder="Zipcode" onChange={this.changeZipcode} value={zipcode} />
                </div>
              </dd>

              <dt className="col-sm-3 text-right">
                Latitude
                <div className="fs-10 thin">Used for geolocation, default is Chicago</div>
              </dt>
              <dd className="col-sm-9">
                <div className="input-group mb-3">
                  <input type="text" className="form-control" placeholder="Latitude" onChange={this.changeLatitude} value={latitude} />
                </div>
              </dd>

              <dt className="col-sm-3 text-right">
                Longitude
                <div className="fs-10 thin">Used for geolocation, default is Chicago</div>
              </dt>
              <dd className="col-sm-9">
                <div className="input-group mb-3">
                  <input type="text" className="form-control" placeholder="Longitude" onChange={this.changeLongitude} value={longitude} />
                </div>
              </dd>

              <dt className="col-sm-3 text-right">
                Notes
                <div className="fs-10 thin">(Shown on the mobile app, general notes about the machine for the customer)</div>
              </dt>
              <dd className="col-sm-9">
                <div className="input-group mb-3">
                  <textarea className="form-control" placeholder="Notes" onChange={this.changeNotes} value={notes} />
                </div>
              </dd>

              <dt className="col-sm-3 text-right">
                Retail Aware Location ID
                <div className="fs-10 thin">(The Location ID for a machine from Retail Aware)</div>
              </dt>
              <dd className="col-sm-9">
                <div className="input-group mb-3">
                  <input type="text" className="form-control" placeholder="RA Location ID" onChange={this.changeRaMachineId} value={raMachineId} />
                </div>
              </dd>

              <dt className="col-sm-3 text-right">
                Email
                <div className="fs-10 thin">(Email used to login to machine)</div>
              </dt>
              <dd className="col-sm-9">
                <div className="input-group mb-3">
                  <input type="text" className="form-control" placeholder="Email" onChange={this.changeEmail} value={email} />
                </div>
              </dd>

              <dt className="col-sm-3 text-right">
                Password
                <div className="fs-10 thin">(Password used to login to machine)</div>
              </dt>
              <dd className="col-sm-9">
                <div className="input-group mb-3">
                  <input type="text" className="form-control" placeholder="Password" onChange={this.changePassword} value={password} />
                </div>
              </dd>

              <dt className="col-sm-3 text-right">
                Automatic?
                <div className="fs-10 thin">(Physical Machine (true) vs In-Person (false))</div>
              </dt>
              <dd className="col-sm-9">
                <div className="input-group mb-3">
                  <div className="form-check">
                    <input type="checkbox" className="form-check-input" onChange={this.changeAutomatic} checked={automatic} />
                  </div>
                </div>
              </dd>

              <dt className="col-sm-3 text-right">
                Legacy Machine?
                <div className="fs-10 thin">(Is this an older legacy machine that does not use our API?)</div>
              </dt>
              <dd className="col-sm-9">
                <div className="input-group mb-3">
                  <div className="form-check">
                    <input type="checkbox" className="form-check-input" onChange={this.changeApi} checked={api} />
                  </div>
                </div>
              </dd>

              <dt className="col-sm-3 text-right"></dt>
              <dd className="col-sm-9">
                <button type="button" className="btn btn-warning" onClick={this.editRecord}>Save</button>
              </dd>
            </dl>

            <Errors errors={errors} />
          </div>
        </div>
      </div>
    );
  }
}
