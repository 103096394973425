import React from "react"
import Nav from "../shared/Nav";
import ShowHeader from "../shared/headers/ShowHeader";
import AdminHeader from "../shared/headers/AdminHeader";
import Errors from "../shared/Errors";

export default class Show extends React.Component {
  constructor(props) {
    super(props);
    self = this;

    this.state = {
      image: null,
      imageUploaded: false,
      errors: '',
      message: '',
      onDesktop: true
    }

    this.addImage = this.addImage.bind(this)
    this.changeMessage = this.changeMessage.bind(this)
    this.newRecord = this.newRecord.bind(this)
  }

  componentDidMount() {
    let onDesktop = window && window.innerWidth >= 1000;

    this.setState({
      onDesktop: onDesktop
    })
  }

  addImage(event) {
    this.setState({
      image: event.target.files[0],
      imageUploaded: true
    })
  }

  changeMessage(event) {
    this.setState({
      message: event.target.value
    })
  }

  newRecord() {
    let that = this;
    const formData = new FormData();
    let data = JSON.stringify({
      conversation_id: that.props.conversation.id,
      message: this.state.message
    })
    formData.append('text_message', data)

    if (this.state.imageUploaded) {
      formData.append('image', this.state.image)
    }

    fetch(this.props.url_create, {
      method: 'POST',
      headers: {},
      body: formData
    }).then(response => {
      return response.json();
    }).then((data) => {
      if (data.errors) {
        that.setState({
          errors: data.errors
        })
      } else {
        that.setState({
          message: ''
        })

        window.location.reload()
      }
    })
  }

  render () {
    let { conversation, showdrop_logo } = this.props;
    let { imageUploaded, errors, message, onDesktop } = this.state;
    let pageName = 'conversations';

    return (
      <div className="container-fluid bg-extra-gray fs-14">
        <AdminHeader
          logo={showdrop_logo}
          onDesktop={onDesktop}
          pageName={pageName}
          counts={this.props.counts}
        />

        <div className="row full-height">
          <div className={`col col-lg-2 nav-restrict-size ${onDesktop ? '' : 'hide'}`}>
            <Nav
              active={pageName}
              counts={this.props.counts}
            />
          </div>

          <div className="col bg-white">
            <ShowHeader
              name={conversation.phone}
              subtext={`(${conversation.customer.name} // ${conversation.customer.email})`}
              url_edit={null}
              url_new={null}
            />

            <dl className="row p20">
              {conversation.messages.map(textMessage => {
                return (
                  <DetermineSenderInMessage
                    textMessage={textMessage}
                  />
                )
              })}

              <>
                <dt className="col-sm-9 mt-20">
                  <textarea className="form-control" placeholder="Message" onChange={this.changeMessage} value={message} />
                </dt>

                <dd className="col-sm-1 center-text mgb-0 mt-20">
                  <div className="attachment-upload">
                    <label for="formFile" className={`m0 border-showdrop br-4 fs-20 w100p h100p ${imageUploaded ? "bg-showdrop color-white" : "bg-white color-primary"}`}>
                      <i className="fas fa-paperclip pdtb-20"></i>
                    </label>

                    <input className="form-control" type="file" id="formFile" onChange={this.addImage} />
                  </div>
                </dd>

                <dd className="col-sm-2 center-text mgb-0 mt-20">
                  <button type="button" className="btn btn-success w100p h100p" onClick={this.newRecord}>Send</button>
                </dd>
              </>
            </dl>

            <Errors errors={errors} />
          </div>
        </div>
      </div>
    );
  }
}

function DetermineSenderInMessage(props) {
  let { textMessage } = props;

  if (textMessage.internal) {
    if (textMessage.notification) {
      return (
        <>
          <dd className="col-sm-6"></dd>
          <dd className="col-sm-6">
            <div className="bg-light-purple color-white p10 br-4">
              <ShowMessage
                message={textMessage.message}
                timestamp={textMessage.timestamp}
              />

              <ShowImage
                imageUrl={textMessage.image_url}
              />
            </div>
          </dd>
        </>
      )
    } else {
      return (
        <>
          <dd className="col-sm-6"></dd>
          <dd className="col-sm-6">
            <div className="bg-showdrop color-white p10 br-4">
              <ShowMessage
                message={textMessage.message}
                timestamp={textMessage.timestamp}
              />

              <ShowImage
                imageUrl={textMessage.image_url}
              />
            </div>
          </dd>
        </>
      )
    }
  } else {
    return (
      <>
        <dd className="col-sm-6">
          <div className="bg-extra-gray p10 br-4">
            <ShowMessage
              message={textMessage.message}
              timestamp={textMessage.timestamp}
            />

            <ShowImage
              imageUrl={textMessage.image_url}
            />
          </div>
        </dd>
        <dd className="col-sm-6"></dd>
      </>
    )
  }
}

function ShowMessage(props) {
  let { message, timestamp } = props;

  if (message) {
    return (
      <span>
        <div className="fs-12 italic pb-10">{timestamp}</div>
        <div className="fs-14 bold">{message}</div>
      </span>
    )
  } else {
    return null;
  }
}

function ShowImage(props) {
  let { imageUrl } = props;

  if (imageUrl) {
    return (
      <div className="center-text">
        <img className="w300 hoverable" src={imageUrl} onClick={()=> window.open(imageUrl, "_blank")} />
      </div>
    )
  } else {
    return null;
  }
}
