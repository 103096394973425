import React from "react"
import Nav from "../shared/Nav";
import IndexHeader from "../shared/headers/IndexHeader";
import IndexButtons from "../shared/buttons/IndexButtons";
import AdminHeader from "../shared/headers/AdminHeader";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export default class Index extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      conversationPhone: "",
      conversations: this.props.conversations,
      downloading: false,
      message: "",
      onDesktop: true,
      phone: "",
      searching: false,
    };

    this.changeMessage = this.changeMessage.bind(this)
    this.changeConversationPhone = this.changeConversationPhone.bind(this)
    this.changePhone = this.changePhone.bind(this)
    this.createConversation = this.createConversation.bind(this)
    this.downloadRawConversationsCsv = this.downloadRawConversationsCsv.bind(this)
    this.searchMessage = this.searchMessage.bind(this)
    this.searchPhone = this.searchPhone.bind(this)
  }

  componentDidMount() {
    let onDesktop = window && window.innerWidth >= 1000;

    this.setState({
      onDesktop: onDesktop
    })
  }

  changeMessage(event) {
    this.setState({
      message: event.target.value
    });
  }

  changeConversationPhone({ target: { value } }) {
    this.setState(prevState => ({
      conversationPhone: normalizeInput(value, prevState.phone)
    }));
  }

  changePhone({ target: { value } }) {
    this.setState(prevState => ({
      phone: normalizeInput(value, prevState.phone)
    }));
  }

  createConversation() {
    let that = this;
    let data = JSON.stringify({
      phone: this.state.conversationPhone,
    })

    fetch(this.props.url_start_conversation, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: data
    }).then(response => {
      return response.json();
    }).then((data) => {
      if (data.errors) {
        that.setState({
          errors: data.errors
        })
      } else {
        window.location.assign(data.url)
      }
    })
  }

  downloadRawConversationsCsv() {
    let that = this;
    that.setState({
      downloading: true
    })

    fetch(this.props.url_download_raw_conversations_csv, {
      method: 'GET',
      headers: {}
    }).then(response => {
      return response.blob();
    }).then((blob) => {
      that.setState({
        downloading: false
      })
      const url = window.URL.createObjectURL(
        new Blob([blob]),
      );
      const link = document.createElement('a');
      link.href = url;
      var today = new Date(),
      date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

      link.setAttribute(
        'download',
        `raw-conversations_${date}.csv`,
      );

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    })
  }

  searchMessage() {
    this.setState({
      searching: true
    })

    let that = this;
    let data = JSON.stringify({
      message: this.state.message,
    })

    fetch(this.props.url_search_by_message, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: data
    }).then(response => {
      return response.json();
    }).then((data) => {
      if (data.errors) {
        that.setState({
          errors: data.errors
        })
      } else {
        that.setState({
          searching: false,
          conversations: data.conversations
        })
      }
    })
  }

  searchPhone() {
    this.setState({
      searching: true
    })

    let that = this;
    let data = JSON.stringify({
      phone: this.state.phone,
    })

    fetch(this.props.url_search_by_phone, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: data
    }).then(response => {
      return response.json();
    }).then((data) => {
      if (data.errors) {
        that.setState({
          errors: data.errors
        })
      } else {
        that.setState({
          searching: false,
          conversations: data.conversations
        })
      }
    })
  }

  render () {
    let { conversations_length, showdrop_logo, url_new } = this.props;
    let { conversationPhone, conversations, downloading, onDesktop, message, phone, searching } = this.state;
    let pageName = 'conversations';

    const unreadCount = (count) => {
      if (count > 0) {
        return (
          <div className="bg-red bold color-white p5 w30 h30 br-100 center-text">{count}</div>
        )
      } else {
        return null
      }
    }
    const checkIfUnsubbed = (conversation) => {
      if (conversation.unsubbed) {
        return (
          <div style={{ color:"red" }}>
            <div style={{ textDecoration:"line-through" }}>{conversation.phone}</div>
            <div style={{ fontSize:"8px",fontStyle:"italic" }}>Ubsubbed</div>
          </div>
        )
      } else {
        return (
          <>{conversation.phone}</>
        )
      }
    }
    const displayMessageInLastDay = (count) => {
      if (count >= 5) {
        return (
          <span style={{ color:"red",paddingLeft:"2px",fontSize:"12px",fontWeight:"bold" }}>[{count}]</span>
        )
      } else if (count > 0) {
        return (
          <span style={{ color:"gray",paddingLeft:"2px",fontSize:"12px" }}>[{count}]</span>
        )
      } else {
        return null;
      }
    }

    return (
      <div className="container-fluid bg-extra-gray fs-14">
        <AdminHeader
          logo={showdrop_logo}
          onDesktop={onDesktop}
          pageName={pageName}
          counts={this.props.counts}
        />

        <div className="row full-height">
          <div className={`col col-lg-2 nav-restrict-size ${onDesktop ? '' : 'hide'}`}>
            <Nav
              active={pageName}
              counts={this.props.counts}
            />
          </div>

          <div className="col col-lg-10 bg-white h1000 o-auto mobile-block">
            <IndexHeader
              name={'Conversations'}
              url={url_new}
            />

            <div className="row pb-10 pos-r">
              <div className="col pos-a">
                <span className="bold">{conversations_length}</span> total conversations
                <div className="italic" style={{ fontSize:"10px" }}>(1000 displayed by default)</div>
              </div>

              <div className="col">
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 100, hide: 100 }}
                  overlay={renderTooltip("Download Raw Conversations")}
                >
                  <button type="button" className="btn btn-sm btn-success mb-5px fs-17 w35 floatr" onClick={this.downloadRawConversationsCsv} disabled={downloading ? "disabled" : ''}>
                    {
                      downloading ? (
                        <i className="fas fa-circle-notch fa-spin"></i>
                      ) : (
                        <i className="fas fa-download"></i>
                      )
                    }
                  </button>
                </OverlayTrigger>
              </div>
            </div>

            <div className="d-flex" style={{ padding:"20px 0" }}>
              <div className="f1">
                <div className="d-flex" style={{ padding:"0 10px" }}>
                  <input type="tel" className="form-control br-r-4 f1" placeholder="Enter Phone.." onChange={this.changePhone} pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" value={phone} disabled={searching ? 'disabled' : ''} />

                  <button className="btn btn-primary btn-sm bold f1" style={{ maxWidth:"100px",marginLeft:"10px" }} type="submit" onClick={searching ? null : this.searchPhone} disabled={searching ? 'disabled' : ''}>
                    {
                      searching ? (
                        <i className="fas fa-circle-notch fa-spin"></i>
                      ) : (
                        "Search"
                      )
                    }
                  </button>
                </div>

                <div style={{ padding:"3px 10px",fontSize:"10px",fontStyle:"italic" }}>
                  Search by a specific number
                </div>
              </div>

              <div className="f1">
                <div className="d-flex" style={{ padding:"0 10px" }}>
                  <input className="form-control br-r-4 f1" placeholder="Enter Message.." onChange={this.changeMessage} value={message} disabled={searching ? 'disabled' : ''} />

                  <button className="btn btn-primary btn-sm bold f1" style={{ maxWidth:"100px",marginLeft:"10px" }} type="submit" onClick={searching ? null : this.searchMessage} disabled={searching ? 'disabled' : ''}>
                    {
                      searching ? (
                        <i className="fas fa-circle-notch fa-spin"></i>
                      ) : (
                        "Search"
                      )
                    }
                  </button>
                </div>

                <div style={{ padding:"3px 10px",fontSize:"10px",fontStyle:"italic" }}>
                  Search by a specific message
                </div>
              </div>

              <div className="f1">
                <div className="d-flex" style={{ padding:"0 10px" }}>
                  <input className="form-control br-r-4 f1" placeholder="(Ex. 12345678901)" onChange={this.changeConversationPhone} value={conversationPhone} disabled={searching ? 'disabled' : ''} />

                  <button className="btn btn-success btn-sm bold f1" style={{ maxWidth:"100px",marginLeft:"10px" }} type="submit" onClick={searching ? null : this.createConversation} disabled={searching ? 'disabled' : ''}>
                    Start
                  </button>
                </div>

                <div style={{ padding:"3px 10px",fontSize:"10px",fontStyle:"italic" }}>
                  Text a specific number
                </div>
              </div>
            </div>


            <table className="table table-long table-hover">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col">ID</th>
                  <th scope="col">Phone</th>
                  <th scope="col">Messages</th>
                  <th scope="col">Latest Message</th>
                  <th scope="col">Latest Timestamp</th>
                  <th scope="col" className="w200"></th>
                </tr>
              </thead>
              <tbody>
                {conversations.map(conversation => {
                  return (
                    <tr>
                      <td>{unreadCount(conversation.unread_count)}</td>
                      <th scope="row">{conversation.id}</th>
                      <td>{checkIfUnsubbed(conversation)}</td>
                      <td>
                        {conversation.text_messages}
                        {displayMessageInLastDay(conversation.messages_in_last_day)}
                      </td>
                      <td>{conversation.latest_message}</td>
                      <td>{conversation.latest_timestamp}</td>
                      <td>
                        <IndexButtons
                          id={conversation.id}
                          url_show={conversation.url}
                          url_edit={null}
                          url_delete={null}
                          hide_message={""}
                        />
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

function renderTooltip(text) {
  return (
    <Tooltip id="button-tooltip">{text}</Tooltip>
  )
};

function normalizeInput(value, _) {
  if (!value) return value;
  const currentValue = value.replace(/[^\d]/g, '');
  const cvLength = currentValue.length;

  if (cvLength <= 11) return currentValue;
  return currentValue.slice(0,11);
};
