import React from "react"

export default class Nav extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render () {
    let { active, counts } = this.props;
    let newOrders = counts.new_orders
    let newSales = counts.new_sales
    let unread = counts.unread_messages
    let newIncompleteJobs = counts.new_incomplete_jobs
    let newErrors = counts.new_errors

    const unreadCount = () => {
      if (unread > 0) {
        return (
          <div className="bg-red bold color-white p2 w20 h20 br-100 center-text fs-12 d-ib">{unread}</div>
        )
      } else {
        return null
      }
    }
    const newOrdersCount = () => {
      if (newOrders > 0) {
        return (
          <div className="bg-red bold color-white p2 w20 h20 br-100 center-text fs-12 d-ib">{newOrders}</div>
        )
      } else {
        return null
      }
    }
    const newSalesCount = () => {
      if (newSales > 0) {
        return (
          <div className="bg-red bold color-white p2 w20 h20 br-100 center-text fs-12 d-ib">{newSales}</div>
        )
      } else {
        return null
      }
    }
    const newIncompleteJobsCount = () => {
      if (newIncompleteJobs > 0) {
        return (
          <div className="bold color-white p2 w20 h20 br-100 center-text fs-12 d-ib" style={{ backgroundColor:"orange" }}>{newIncompleteJobs}</div>
        )
      } else {
        return null
      }
    }
    const newErrorsCount = () => {
      if (newErrors > 0) {
        return (
          <div className="bold color-white p2 w20 h20 br-100 center-text fs-12 d-ib" style={{ backgroundColor:"orange" }}>{newErrors}</div>
        )
      } else {
        return null
      }
    }

    return (
      <div className="nav flex-column nav-pills fs-16 mt-10 mb-20" aria-orientation="vertical">
        <p className="bold m0 fs-18 mt-20">Control Center</p>
        <a className={`nav-link ${active == 'configure_machine' ? 'active bg-showdrop br-0 color-white' : 'color-black'}`} href="/admin/machines">Machines Overview</a>
        <a className={`nav-link ${active == 'create_machine' ? 'active bg-showdrop br-0 color-white' : 'color-black'}`} href="/admin/machines/new">Create Machine</a>
        <a className={`nav-link ${active == 'create_planogram' ? 'active bg-showdrop br-0 color-white' : 'color-black'}`} href="/admin/planograms/new">Build Planogram</a>
        <a className={`nav-link ${active == 'configure_planogram' ? 'active bg-showdrop br-0 color-white' : 'color-black'}`} href="/admin/planograms">Assign Planogram</a>

        <p className="bold m0 fs-18 mt-20">Pre-Set Up</p>
        <a className={`nav-link ${active == 'companies' ? 'active bg-showdrop br-0 color-white' : 'color-black'}`} href="/admin/companies">Company Setup</a>
        <a className={`nav-link ${active == 'brands' ? 'active bg-showdrop br-0 color-white' : 'color-black'}`} href="/admin/brands">Brand Setup</a>
        <a className={`nav-link ${active == 'categories' ? 'active bg-showdrop br-0 color-white' : 'color-black'}`} href="/admin/categories">Product Categories</a>

        <p className="bold m0 fs-18 mt-20">Data Center</p>
        <a className={`nav-link ${active == 'machine_events_v2' ? 'active bg-showdrop br-0 color-white' : 'color-black'}`} href="/admin/metrics/events_v2">Raw Events</a>
        <a className={`nav-link ${active == 'machine_stats' ? 'active bg-showdrop br-0 color-white' : 'color-black'}`} href="/admin/metrics/machines">Machine Stats</a>
        <a className={`nav-link ${active == 'campaign_stats' ? 'active bg-showdrop br-0 color-white' : 'color-black'}`} href="/admin/metrics/campaigns">Campaign Stats</a>
        <a className={`nav-link ${active == 'orders' ? 'active bg-showdrop br-0 color-white' : 'color-black'}`} href="/admin/orders">Shopify Orders {newOrdersCount()}</a>
        <a className={`nav-link ${active == 'sales' ? 'active bg-showdrop br-0 color-white' : 'color-black'}`} href="/admin/sales">Showdrop Sales {newSalesCount()}</a>
        <a className={`nav-link ${active == 'product_ratings' ? 'active bg-showdrop br-0 color-white' : 'color-black'}`} href="/admin/product_ratings">Product Ratings</a>
        <a className={`nav-link ${active == 'product_reviews' ? 'active bg-showdrop br-0 color-white' : 'color-black'}`} href="/admin/product_reviews">Product Reviews</a>
        <a className={`nav-link ${active == 'api_requests' ? 'active bg-showdrop br-0 color-white' : 'color-black'}`} href="/admin/api_requests">API Requests</a>
        <a className={`nav-link ${active == 'job_trackers' ? 'active bg-showdrop br-0 color-white' : 'color-black'}`} href="/admin/job_trackers">Job Trackers {newIncompleteJobsCount()}</a>
        <a className={`nav-link ${active == 'error_trackers' ? 'active bg-showdrop br-0 color-white' : 'color-black'}`} href="/admin/error_trackers">Error Trackers {newErrorsCount()}</a>

        <p className="bold m0 fs-18 mt-20">Messaging</p>
        <a className={`nav-link ${active == 'conversations' ? 'active bg-showdrop br-0 color-white' : 'color-black'}`} href="/admin/conversations">Inbox {unreadCount()}</a>
        <a className={`nav-link ${active == 'text_messages' ? 'active bg-showdrop br-0 color-white' : 'color-black'}`} href="/admin/text_messages">Mass Text</a>

        <p className="bold m0 fs-18 mt-20">Tools</p>
        <a className={`nav-link ${active == 'unlock_samples' ? 'active bg-showdrop br-0 color-white' : 'color-black'}`} href="/admin/unlock_samples">Unlock Samples</a>
        <a className={`nav-link ${active == 'retail_aware' ? 'active bg-showdrop br-0 color-white' : 'color-black'}`} href="/admin/retail_aware_sensors">Upload RA Data</a>
        <a className={`nav-link ${active == 'rebate_events' ? 'active bg-showdrop br-0 color-white' : 'color-black'}`} href="/admin/rebate_events">Submit Rebate Events</a>
        <a className={`nav-link ${active == 'mobile_version' ? 'active bg-showdrop br-0 color-white' : 'color-black'}`} href="/admin/mobile_versions">Set Mobile Version</a>

        <p className="bold m0 fs-18 mt-20">Other</p>
        <a className={`nav-link ${active == 'visits' ? 'active bg-showdrop br-0 color-white' : 'color-black'}`} href="/admin/visits">Visits List</a>
        <a className={`nav-link ${active == 'campaigns' ? 'active bg-showdrop br-0 color-white' : 'color-black'}`} href="/admin/campaigns">Campaigns List</a>
        <a className={`nav-link ${active == 'customers' ? 'active bg-showdrop br-0 color-white' : 'color-black'}`} href="/admin/customers">Customers List</a>

        <p className="bold m0 fs-18 mt-40"></p>
      </div>
    );
  }
}
