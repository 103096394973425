import React from "react"
import Nav from "../shared/Nav";
import ShowHeader from "../shared/headers/ShowHeader";
import AdminHeader from "../shared/headers/AdminHeader";
import IndexButtons from "../shared/buttons/IndexButtons";
import IndexHeader from "../shared/headers/IndexHeader";

export default class Show extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      onDesktop: true,
    }

    this.regenerateImage = this.regenerateImage.bind(this)
  }

  componentDidMount() {
    let onDesktop = window && window.innerWidth >= 1000;

    this.setState({
      onDesktop: onDesktop
    })
  }

  regenerateImage() {
    let that = this;

    this.setState({
      loading: true
    })

    if (confirm("Are you sure you want to regenerate the weekly digest image?")) {
      fetch(this.props.brand.url_generate_image, {
        method: 'GET',
        headers: {}
      }).then(response => {
        return response.json();
      }).then((data) => {
        if (data.errors) {
          that.setState({
            errors: data.errors
          })
        } else {
          window.location.reload()
        }
      })
    } else {
      that.setState({
        loading: false,
      })
    }
  }

  render () {
    let { brand, showdrop_logo, url_new } = this.props;
    let { loading, onDesktop } = this.state;
    let pageName = 'brands';

    return (
      <div className="container-fluid bg-extra-gray fs-14">
        <AdminHeader
          logo={showdrop_logo}
          onDesktop={onDesktop}
          pageName={pageName}
          counts={this.props.counts}
        />

        <div className="row full-height">
          <div className={`col col-lg-2 nav-restrict-size ${onDesktop ? '' : 'hide'}`}>
            <Nav
              active={pageName}
              counts={this.props.counts}
            />
          </div>

          <div className="col bg-white">
            <ShowHeader
              name={brand.name}
              subtext={null}
              url_edit={brand.url_edit}
              url_new={url_new}
            />

            <dl className="row">
              <dt className="col-sm-3 text-right">ID</dt>
              <dd className="col-sm-9">{brand.id}</dd>

              <dt className="col-sm-3 text-right">Name</dt>
              <dd className="col-sm-9">{brand.name}</dd>

              <dt className="col-sm-3 text-right">Description</dt>
              <dd className="col-sm-9">{brand.description || '-'}</dd>

              <dt className="col-sm-3 text-right">Website</dt>
              <dd className="col-sm-9">
                {brand.website ? (
                  <a href={brand.website} target="_blank">{brand.website}</a>
                ) : (
                  '-'
                )}
              </dd>

              <dt className="col-sm-3 text-right">Shopify Linked?</dt>
              <dd className="col-sm-9">{brand.shopify ? 'Yes' : 'No'}</dd>

              <dt className="col-sm-3 text-right">Shopify Store</dt>
              <dd className="col-sm-9">{brand.shop.domain ? brand.shop.domain : '-'}</dd>

              <dt className="col-sm-3 text-right">Klaviyo Account</dt>
              <dd className="col-sm-9">{brand.klaviyo_account || '-'}</dd>

              <dt className="col-sm-3 text-right">Klaviyo List</dt>
              <dd className="col-sm-9">{brand.klaviyo_list || '-'}</dd>

              <dt className="col-sm-3 text-right">Retailer URL</dt>
              <dd className="col-sm-9">{brand.retailer_url || '-'}</dd>

              <dt className="col-sm-3 text-right">Rebate Code Word</dt>
              <dd className="col-sm-9">{brand.rebate_code_word || '-'}</dd>

              <dt className="col-sm-3 text-right">Outgoing Emails Enabled?</dt>
              <dd className="col-sm-9">{brand.enable_outgoing_email ? 'true' : 'false'}</dd>

              <dt className="col-sm-3 text-right">Outgoing Emails</dt>
              <dd className="col-sm-9">{brand.outgoing_emails || '-'}</dd>

              <dt className="col-sm-3 text-right">Rebate Code Word</dt>
              <dd className="col-sm-9">{brand.rebate_code_word || '-'}</dd>

              <dt className="col-sm-3 text-right">Download Password</dt>
              <dd className="col-sm-9">{brand.download_password || '-'}</dd>

              <dt className="col-sm-3 text-right">Portal URL</dt>
              <dd className="col-sm-9">
                <a href={brand.url_portal} target="_blank">{brand.url_portal}</a>
              </dd>

              <dt className="col-sm-3 text-right">Logo</dt>
              <dd className="col-sm-9">
                {brand.logo ? (
                  <img className="w300 h-auto" src={brand.logo_url} />
                ) : (
                  null
                )}

                <div>{brand.logo_name}</div>
              </dd>

              <dt className="col-sm-3 text-right">Shop Image</dt>
              <dd className="col-sm-9">
                {brand.shop_image ? (
                  <img className="w300 h-auto" src={brand.shop_image_url} />
                ) : (
                  null
                )}

                <div>{brand.shop_image_name}</div>
              </dd>

              <dt className="col-sm-3 text-right">Digest Email Image</dt>
              <dd className="col-sm-9">
                {brand.digest_email_image ? (
                  <img className="w300 h-auto" src={brand.digest_email_image_url} />
                ) : (
                  null
                )}

                <div>{brand.digest_email_image_name}</div>
              </dd>

              <dt className="col-sm-3 text-right">Latest Digest Image</dt>
              <dd className="col-sm-9">
                <img className="w300 h-auto" src={brand.digest_image_url} />

                <div>
                  <button type="button" className="btn btn-danger" onClick={loading ? null : this.regenerateImage} style={{ width:"300px",margin:"10px 0" }} disabled={loading ? 'disabled' : ''}>
                    {
                      loading ? (
                        <i className="fas fa-circle-notch fa-spin"></i>
                      ) : (
                        <>Regenerate Digest Image</>
                      )
                    }
                  </button>
                </div>

                <div>{brand.digest_image_url}</div>
              </dd>
            </dl>

            <hr/>

            <div className="col">
              <IndexHeader
                name={'Products'}
                url={brand.url_new_product}
              />

              <table className="table table-hover">
                <thead>
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Image</th>
                    <th scope="col">Name</th>
                    <th scope="col">Description</th>
                    <th scope="col" className="w200"></th>
                  </tr>
                </thead>
                <tbody>
                  {brand.products.map(product => {
                    return (
                      <tr>
                        <th scope="row">{product.id}</th>
                        <td>
                          {product.logo ? (
                            <img className="w70 h-auto" src={product.logo_url} />
                          ) : (
                            null
                          )}
                        </td>
                        <td>{product.name}</td>
                        <td>{product.description}</td>
                        <td>
                          <IndexButtons
                            id={product.id}
                            url_show={product.url}
                            url_edit={product.url_edit}
                            url_delete={product.url_delete}
                            hide_message={"Are you sure?"}
                          />
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>

            <hr/>

            <div className="col">
              <IndexHeader
                name={'Packages'}
                url={brand.url_new_campaign_package}
              />

              <table className="table table-hover">
                <thead>
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Name</th>
                    <th scope="col">Created At</th>
                    <th scope="col" className="w200"></th>
                  </tr>
                </thead>
                <tbody>
                  {brand.packages.map(pk => {
                    return (
                      <tr>
                        <td>{pk.id}</td>
                        <td>{pk.name}</td>
                        <td>{pk.created_at}</td>
                        <td>
                          <IndexButtons
                            id={pk.id}
                            url_show={pk.url}
                            url_edit={pk.url_edit}
                            url_delete={pk.url_delete}
                            hide_message={"Are you sure?"}
                          />
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
