import React from "react"
import Nav from "../shared/Nav";
import EditHeader from "../shared/headers/EditHeader";
import Errors from "../shared/Errors";
import AdminHeader from "../shared/headers/AdminHeader";

export default class Edit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      description: this.props.brand.description,
      digestEmailImage: this.props.brand.digest_email_image,
      digestEmailImageUploaded: false,
      enableOutgoingEmail: this.props.brand.enable_outgoing_email,
      errors: '',
      klaviyoAccount: this.props.brand.klaviyo_account,
      klaviyoList: this.props.brand.klaviyo_list,
      logo: this.props.brand.logo,
      logoUploaded: false,
      name: this.props.brand.name,
      onDesktop: true,
      outgoingEmails: this.props.brand.outgoing_emails,
      rebateCodeWord: this.props.brand.rebate_code_word,
      retailerUrl: this.props.brand.retailer_url,
      selectedShop: this.props.brand.shop.id,
      shopImage: this.props.brand.shop_image,
      shopImageUploaded: false,
      shops: this.props.shops,
      website: this.props.brand.website,
    };

    this.changeDescription = this.changeDescription.bind(this)
    this.changeDigestEmailImage = this.changeDigestEmailImage.bind(this)
    this.changeEnableOutgoingEmail = this.changeEnableOutgoingEmail.bind(this)
    this.changeKlaviyoAccount = this.changeKlaviyoAccount.bind(this)
    this.changeKlaviyoList = this.changeKlaviyoList.bind(this)
    this.changeLogo = this.changeLogo.bind(this)
    this.changeName = this.changeName.bind(this)
    this.changeOutgoingEmails = this.changeOutgoingEmails.bind(this)
    this.changeRebateCodeWord = this.changeRebateCodeWord.bind(this)
    this.changeRetailerUrl = this.changeRetailerUrl.bind(this)
    this.changeShop = this.changeShop.bind(this)
    this.changeShopImage = this.changeShopImage.bind(this)
    this.changeWebsite = this.changeWebsite.bind(this)
    this.editRecord = this.editRecord.bind(this)
  }

  componentDidMount() {
    let onDesktop = window && window.innerWidth >= 1000;

    this.setState({
      onDesktop: onDesktop
    })
  }

  changeDescription(event) {
    this.setState({
      description: event.target.value
    })
  }

  changeDigestEmailImage(event) {
    this.setState({
      digestEmailImage: event.target.files[0],
      digestEmailImageUploaded: true
    })
  }

  changeEnableOutgoingEmail(event) {
    let enableOutgoingEmail = !this.state.enableOutgoingEmail;

    this.setState({
      enableOutgoingEmail: enableOutgoingEmail
    })
  }

  changeKlaviyoAccount(event) {
    this.setState({
      klaviyoAccount: event.target.value
    })
  }

  changeKlaviyoList(event) {
    this.setState({
      klaviyoList: event.target.value
    })
  }

  changeLogo(event) {
    this.setState({
      logo: event.target.files[0],
      logoUploaded: true
    })
  }

  changeName(event) {
    this.setState({
      name: event.target.value
    })
  }

  changeOutgoingEmails(event) {
    this.setState({
      outgoingEmails: event.target.value
    })
  }

  changeRebateCodeWord(event) {
    this.setState({
      rebateCodeWord: event.target.value
    })
  }

  changeRetailerUrl(event) {
    this.setState({
      retailerUrl: event.target.value
    })
  }

  changeShop(event) {
    let shopId = Number(event.target.value);

    this.setState({
      selectedShop: shopId,
    })
  }

  changeShopImage(event) {
    this.setState({
      shopImage: event.target.files[0],
      shopImageUploaded: true
    })
  }

  changeWebsite(event) {
    this.setState({
      website: event.target.value
    })
  }

  editRecord() {
    let that = this;
    const formData = new FormData();
    let data = JSON.stringify({
      description: this.state.description,
      enable_outgoing_email: this.state.enableOutgoingEmail,
      klaviyo_account: this.state.klaviyoAccount,
      klaviyo_list: this.state.klaviyoList,
      name: this.state.name,
      outgoing_emails: this.state.outgoingEmails,
      rebate_code_word: this.state.rebateCodeWord,
      retailer_url: this.state.retailerUrl,
      shop_id: this.state.selectedShop,
      website: this.state.website,
    })
    formData.append('brand', data)

    if (this.state.logoUploaded) {
      formData.append('logo', this.state.logo)
    }
    if (this.state.shopImageUploaded) {
      formData.append('shop_image', this.state.shopImage)
    }
    if (this.state.digestEmailImageUploaded) {
      formData.append('digest_email_image', this.state.digestEmailImage)
    }

    fetch(this.props.brand.edit_path, {
      method: 'PUT',
      headers: {},
      body: formData
    }).then(response => {
      return response.json();
    }).then((data) => {
      if (data.errors) {
        that.setState({
          errors: data.errors
        })
      } else {
        window.location.assign(data.url)
      }
    })
  }

  render () {
    let { brand, shops, showdrop_logo, url_new } = this.props;
    let {
      description,
      enableOutgoingEmail,
      errors,
      klaviyoAccount,
      klaviyoList,
      name,
      onDesktop,
      outgoingEmails,
      rebateCodeWord,
      retailerUrl,
      selectedShop,
      website,
    } = this.state;
    let pageName = 'brands';

    return (
      <div className="container-fluid bg-extra-gray fs-14">
        <AdminHeader
          logo={showdrop_logo}
          onDesktop={onDesktop}
          pageName={pageName}
          counts={this.props.counts}
        />

        <div className="row full-height">
          <div className={`col col-lg-2 nav-restrict-size ${onDesktop ? '' : 'hide'}`}>
            <Nav
              active={pageName}
              counts={this.props.counts}
            />
          </div>

          <div className="col bg-white">
            <EditHeader
              name={brand.name}
              url_show={brand.url}
              url_new={url_new}
            />

            <dl className="row">
              <dt className="col-sm-3 text-right">
                Name
                <div className="fs-10 thin">Character Count: {name.length}</div>
              </dt>
              <dd className="col-sm-9">
                <div className="input-group mb-3">
                  <input type="text" className="form-control" placeholder="Name" onChange={this.changeName} value={name} />
                </div>
              </dd>

              <dt className="col-sm-3 text-right">Website</dt>
              <dd className="col-sm-9">
                <div className="input-group mb-3">
                  <input type="text" className="form-control" placeholder="Website" onChange={this.changeWebsite} value={website} />
                </div>
              </dd>

              <dt className="col-sm-3 text-right">
                Description
                <div className="fs-10 thin">Character Count: {description.length}</div>
              </dt>
              <dd className="col-sm-9">
                <div className="input-group mb-3">
                  <textarea className="form-control" placeholder="Description" onChange={this.changeDescription} value={description} />
                </div>
              </dd>

              <dt className="col-sm-3 text-right">Shop</dt>
              <dd className="col-sm-9">
                <select className="form-select" value={selectedShop} onChange={this.changeShop}>
                  <option value="" disabled selected>Select a Shop</option>

                  {shops.map(shop => {
                    return (
                      <option key={shop.id} value={shop.id} disabled={shop.brand && (shop.id != selectedShop) ? 'disabled' : ''}>{shop.brand ? `${shop.domain}` : shop.domain}</option>
                    )
                  })}
                </select>
              </dd>

              <dt className="col-sm-3 text-right">Logo</dt>
              <dd className="col-sm-9">
                <label>{brand.logo_name}</label>
                <input className="form-control" type="file" id="formFile" onChange={this.changeLogo} />
              </dd>

              <dt className="col-sm-3 text-right">
                Shop Image
                <div className="fs-10 thin">(Image displayed on mobile's Shop Brands page)</div>
              </dt>
              <dd className="col-sm-9">
                <label>{brand.shop_image_name}</label>
                <input className="form-control" type="file" id="formFile" onChange={this.changeShopImage} />
              </dd>

              <dt className="col-sm-3 text-right">
                Digest Image
                <div className="fs-10 thin">(Image displayed at the top of the weekly digest email sent to brands)</div>
              </dt>
              <dd className="col-sm-9">
                <label>{brand.digest_email_image_name}</label>
                <input className="form-control" type="file" id="formFile" onChange={this.changeDigestEmailImage} />
              </dd>

              <dt className="col-sm-3 text-right">
                Klaviyo Account
                <div className="fs-10 thin">(ex. pk_xxxxxxxxxxxxxxxx)</div>
              </dt>
              <dd className="col-sm-9">
                <div className="input-group mb-3">
                  <input type="text" className="form-control" placeholder="Klaviyo Account" onChange={this.changeKlaviyoAccount} value={klaviyoAccount} />
                </div>
              </dd>

              <dt className="col-sm-3 text-right">
                Klaviyo List
                <div className="fs-10 thin">(list ID)</div>
              </dt>
              <dd className="col-sm-9">
                <div className="input-group mb-3">
                  <input type="text" className="form-control" placeholder="Klaviyo List" onChange={this.changeKlaviyoList} value={klaviyoList} />
                </div>
              </dd>

              <dt className="col-sm-3 text-right">
                Retailer URL
                <div className="fs-10 thin">(A link to a brand's "where to find us" page)</div>
              </dt>
              <dd className="col-sm-9">
                <div className="input-group mb-3">
                  <input type="text" className="form-control" placeholder="Retailer URL" onChange={this.changeRetailerUrl} value={retailerUrl} />
                </div>
              </dd>

              <dt className="col-sm-3 text-right">
                Rebate Code Word
                <div className="fs-10 thin">(What a user will text us to sign up for rebates  - ex. STIXREBATE)</div>
              </dt>
              <dd className="col-sm-9">
                <div className="input-group mb-3">
                  <input type="text" className="form-control" placeholder="Rebate Code Word" onChange={this.changeRebateCodeWord} value={rebateCodeWord} />
                </div>
              </dd>

              <dt className="col-sm-3 text-right">
                Enable Outgoing Emails?
                <div className="fs-10 thin">(Allows for a brand to recieve the weekly digest email)</div>
              </dt>
              <dd className="col-sm-9">
                <div className="input-group mb-3">
                  <div className="form-check">
                    <input type="checkbox" className="form-check-input" onChange={this.changeEnableOutgoingEmail} checked={enableOutgoingEmail} />
                  </div>
                </div>
              </dd>

              <dt className="col-sm-3 text-right">
                Outgoing Email Addresses
                <div className="fs-10 thin">(seperate by comma)</div>
              </dt>
              <dd className="col-sm-9">
                <div className="input-group mb-3">
                  <input type="text" className="form-control" placeholder="(ex. email1,email2,email3)" onChange={this.changeOutgoingEmails} value={outgoingEmails} disabled={enableOutgoingEmail ? '' : 'disabled'} />
                </div>
              </dd>

              <dt className="col-sm-3 text-right"></dt>
              <dd className="col-sm-9">
                <button type="button" className="btn btn-warning" onClick={this.editRecord}>Update Brand</button>
              </dd>
            </dl>

            <Errors errors={errors} />
          </div>
        </div>
      </div>
    );
  }
}
